<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <reorder-folder-left
          type="variable"
          :data="folders"
          :isPc="isPc"
          :selectedFolder="selectedFolderIndex"
          @changeSelectedFolder="onSelectedFolderChanged"
          @submitUpdateFolder="submitUpdateFolder"
          @submitCreateFolder="submitCreateFolder"
          @reorder="onDragFolderEnd"
        />
        <div class="flex-grow-1 folder-right" :key="contentKey">
          <div class="d-flex align-items-center">
            <a
              v-if="folders && folders.length && curFolder"
              :href="`${rootPath}/user/variables/new?folder_id=${curFolder.id}`"
              class="btn btn-primary"
            >
              <i class="uil-plus"></i> 新規作成
            </a>
            <!-- START: Search form -->
            <div class="ml-auto d-flex">
              <div class="input-group app-search">
                <input
                  type="text"
                  class="form-control dropdown-toggle fw-250"
                  placeholder="検索..."
                  v-model="queryParams.name_cont"
                />
                <span class="mdi mdi-magnify search-icon"></span>
                <div class="input-group-append">
                  <div class="btn btn-primary" @click="search()">検索</div>
                </div>
              </div>
            </div>
          </div>
          <!-- End: Search form -->
          <div class="table-responsive mt-2" v-if="curFolder">
            <div class="overflow-auto table-content">
              <table class="table table-centered mb-0">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th class="mw-150">友だち情報欄名</th>
                    <th class="mw-120">既定値</th>
                    <th class="mw-60">人数</th>
                    <th class="mw-60">操作</th>
                    <th class="mw-130">フォルダー</th>
                    <th class="mw-130"></th>
                  </tr>
                </thead>
                <draggable
                  tag="tbody"
                  v-model="variablesCopy"
                  @end="onDragVariableEnd"
                  drag-class="dragging-item"
                  ghost-class="destination-item"
                >
                  <tr
                    v-for="(variable, index) in variablesCopy"
                    :key="variable.id"
                    class="draggable-row"
                  >
                    <td class="drag-icon-cell">
                      <i class="mdi mdi-drag text-secondary"></i>
                    </td>
                    <td>
                      <p class="item-name vw-10">{{ variable.name }}</p>
                    </td>
                    <td>
                      <p class="item-name vw-10">{{ variable.default || "-" }}</p>
                    </td>
                    <td>{{ variable.friends_count }}</td>
                    <td>
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-light btn-sm dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          操作 <span class="caret"></span>
                        </button>
                        <div class="dropdown-menu">
                          <a role="button" class="dropdown-item" @click="openEdit(variable)">友だち情報欄を編集</a>
                          <a
                            role="button"
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#modalCopyVariable"
                            @click="curVariableIndex = index"
                            >友だち情報欄をコピー</a
                          >
                          <a
                            role="button"
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#modalDeleteVariable"
                            @click="curVariableIndex = index"
                            v-if="variable.destroyable"
                            >友だち情報欄を削除</a
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>{{ curFolder.name }}</div>
                      <div class="text-sm">{{ formattedDate(variable.created_at) }}</div>
                    </td>
                    <td>
                      <div class="actions ml-auto mr-2">
                        <button
                          @click.stop="moveVariable(index, 'up')"
                          :class="{ disabled: index === 0 && queryParams.page === 1 }"
                          class="btn btn-light btn-sm action-button"
                          :disabled="index === 0 && queryParams.page === 1"
                        >
                          <i class="dripicons-chevron-up"></i>
                        </button>
                        <button
                          @click.stop="moveVariable(index, 'down')"
                          :class="{ disabled: index === variablesCopy.length - 1 && queryParams.page === totalPages }"
                          class="btn btn-light btn-sm action-button"
                          :disabled="index === variablesCopy.length - 1 && queryParams.page === totalPages"
                        >
                          <i class="dripicons-chevron-down"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
            <div class="d-flex justify-content-center mt-3 text-center">
              <b-pagination
                  v-if="totalRows > perPage"
                  v-model="queryParams.page"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @change="loadPage"
                  aria-controls="my-table"
              ></b-pagination>
            </div>
            <div class="text-center mt-5" v-if="!loading && totalRows === 0">
              <b>友だち情報欄はありません。</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-indicator :loading="loading"></loading-indicator>

    <!-- START: Delete folder modal -->
    <modal-confirm
      title="このフォルダーを削除してもよろしいですか？"
      id="modalDeleteFolder"
      type="delete"
      @confirm="submitDeleteFolder"
    >
      <template v-slot:content v-if="curFolder">
        <span>フォルダー名：{{ curFolder.name }}</span>
      </template>
    </modal-confirm>
    <!-- END: Delete folder modal -->

    <!-- START: Delete variable modal -->
    <modal-confirm
      title="この友だち情報欄を削除してもよろしいですか？"
      id="modalDeleteVariable"
      type="delete"
      @confirm="submitDeleteVariable"
    >
      <template v-slot:content>
        <div v-if="curVariable">
          友だち情報欄名：<b>{{ curVariable.name }}</b>
        </div>
      </template>
    </modal-confirm>
    <!-- END: Delete variable modal -->

    <!-- START: Copy variable modal -->
    <modal-confirm
      title="この友だち情報欄をコピーしてもよろしいですか？"
      id="modalCopyVariable"
      type="confirm"
      @confirm="submitCopyVariable"
    >
      <template v-slot:content>
        <div v-if="curVariable">
          友だち情報欄名：<b>{{ curVariable.name }}</b>
        </div>
      </template>
    </modal-confirm>
    <!-- END: Copy variable modal -->
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import Util from '@/core/util';

export default {
  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      isPc: true,
      selectedFolderIndex: 0,
      curVariableIndex: null,
      loading: true,
      contentKey: 0,
      queryParams: {},
      variablesCopy: []
    };
  },

  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },

  async beforeMount() {
    await this.getFolders();
    const folderId = Number.parseInt(Util.getParamFromUrl('folder_id'));
    const index = folderId > 0 ? _.findIndex(this.folders, _ => _.id === folderId) : 0;
    this.onSelectedFolderChanged(index > 0 ? index : 0);
    this.loading = false;
  },

  computed: {
    ...mapGetters('variable', ['getQueryParams']),
    ...mapState('variable', {
      folders: state => state.folders,
      variables: state => state.variables,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolderIndex];
    },

    curVariable() {
      return this.curFolder ? this.variables[this.curVariableIndex] : null;
    },

    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    }
  },

  methods: {
    ...mapMutations('variable', ['setQueryParams', 'updateFolderOrder', 'updateVariableOrder']),
    ...mapActions('variable', [
      'getFolders',
      'deleteVariable',
      'copyVariable',
      'deleteFolder',
      'createFolder',
      'updateFolder',
      'getVariables',
      'reorderFolders',
      'reorderVariables'
    ]),

    async onDragFolderEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.folders,
        this.reorderFolders,
        this.updateFolderOrder
      );
    },

    async moveVariable(index, direction) {
      this.queryParams.page = await Util.moveItem(
        this.variables,
        index,
        direction,
        this.reorderVariables.bind(this),
        this.updateVariableOrder.bind(this),
        null,
        this.queryParams.page,
        this.totalPages
      );

      await this.loadPage();
    },

    async onDragVariableEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.variables,
        this.reorderVariables,
        this.updateVariableOrder
      );

      await this.getVariables();
    },

    forceRerender() {
      this.contentKey++;
    },

    onSelectedFolderChanged(index) {
      this.selectedFolderIndex = index;
      this.isPc = true;
      this.queryParams.page = 1;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    async search() {
      this.queryParams.page = 1;
      await this.loadPage(true);
    },

    async submitUpdateFolder(folder) {
      const response = await this.updateFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの変更は完了しました。');
      } else {
        window.toastr.error('フォルダーの変更は失敗しました。');
      }
    },

    async submitCreateFolder(folder) {
      const response = await this.createFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの作成は完了しました。');
      } else {
        window.toastr.error('フォルダーの作成は失敗しました。');
      }
    },

    async submitDeleteFolder() {
      const response = await this.deleteFolder(this.folders[this.selectedFolderIndex].id);
      if (response) {
        window.toastr.success('フォルダーの削除は完了しました。');
        this.onSelectedFolderChanged(0);
      } else {
        window.toastr.error('フォルダーの削除は失敗しました。');
      }
    },

    async submitDeleteVariable() {
      const response = await this.deleteVariable(this.curVariable.id);
      const url = `${this.rootPath}/user/variables?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('友だち情報欄の削除は完了しました。', url);
      } else {
        Util.showErrorThenRedirect('友だち情報欄の削除は失敗しました。', url);
      }
      this.forceRerender();
    },

    async submitCopyVariable() {
      const response = await this.copyVariable(this.curVariable.id);
      const url = `${this.rootPath}/user/variables?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('友だち情報欄のコピーは完了しました。', url);
      } else {
        Util.showErrorThenRedirect('友だち情報欄のコピーは失敗しました。', url);
      }
      this.forceRerender();
    },

    backToFolder() {
      this.isPc = false;
    },

    openEdit(variable) {
      window.open(`${process.env.MIX_ROOT_PATH}/user/variables/${variable.id}/edit`);
    },

    formattedDate(date) {
      return Util.formattedDate(date);
    },

    async loadPage(isLoadFolder = false) {
      this.$nextTick(async() => {
        this.setQueryParams(this.queryParams);
        this.loading = true;
        if (isLoadFolder === true) {
          await this.getFolders({ is_filter: true });
        }
        await this.getVariables();
        this.forceRerender();
        this.variablesCopy = _.cloneDeep(this.variables);
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss"  scoped>
  ::v-deep {
    td .emojione {
      width: 20px !important;
    }

    td .chat-item > .sticker-static {
      width: 50px !important;
    }

    td .chat-item {
      padding: 0px;
    }

    .dragging-item {
      opacity: 0.8;
      border: 2px solid #008a00 !important;
    }

    .destination-item {
      opacity: 0.5;
      border: 2px dashed #6a0dad;
      background-color: #d8bfd8;
    }
  }
</style>

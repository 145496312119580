import MarkAPI from '../api/mark_api';

export const state = {
  marks: [],
  meta: {
    current_page: 1,
    total_pages: 0,
    total_count: 0,
    limit_value: 0
  },
  queryParams: {
    name_cont: null,
    page: 1
  }
};

export const mutations = {
  setQueryParams(state, param) {
    Object.assign(state.queryParams, param);
  },

  setMeta(state, meta) {
    state.meta = meta;
  },

  setMarks(state, marks) {
    state.marks = marks;
  },

  increaseTotalCount(state) {
    state.meta.total_count++;
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  },

  getMeta(state) {
    return state.meta;
  }
};

export const actions = {
  async getMarks(context) {
    try {
      const params = { q: _.omit(state.queryParams, 'page'), page: state.queryParams.page };
      const res = await MarkAPI.list(params);
      context.commit('setMeta', res.meta);
      context.commit('setMarks', res.data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateMarks(context, payload) {
    try {
      const response = await MarkAPI.update(payload);
      return response;
    } catch (error) {
      return null;
    }
  }
};

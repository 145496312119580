<template>
  <div>
    <div class="card">
      <div class="card-header left-border text-md">
        <b>友だち数遷移</b>
      </div>
      <div class="card-body p-0">
        <div class="body-content overflow-auto">
          <table class="table table-centered mb-0">
            <thead>
              <tr>
                <th>日付</th>
                <th>前日比</th>
                <th>登録数</th>
                <th>ブロックされた数</th>
                <th>有効友だち数</th>
                <th>
                  <a v-if="showBtnToDetails" href="/user/friend_statistics" class="btn btn-sm btn-light float-right">さらに詳しく</a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="statistic in statistics" :key="statistic.id">
                <td>
                  {{ moment(statistic.date).format('YYYY年MM月DD日') }}
                </td>
                <td>
                  {{ statistic.added_friends_count - statistic.blocked_friends_count }}
                </td>
                <td>
                  {{ statistic.added_friends_count }}
                </td>
                <td>
                  {{ statistic.blocked_friends_count }}
                </td>
                <td>
                  {{ statistic.total_friends_count }}
                </td>
                <td>
                  <button
                    class="btn btn-sm btn-light"
                    data-toggle="modal"
                    data-target="#modalFriendStatistics"
                    :disabled="statistic.added_friend_ids.length === 0"
                    @click="friendIds = statistic.added_friend_ids"
                  >
                    登録友だちリスト
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="totalRows === 0" class="text-center my-5"><b>統計データがありません</b></div>
        </div>
        <div class="d-flex justify-content-center mt-1" v-if="totalRows > perPage && isPagination">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>

      <loading-indicator :loading="loading"></loading-indicator>
    </div>
    <modal-friend-statistics :friend_ids="friendIds" />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  props: {
    showBtnToDetails: {
      type: Boolean,
      default: false
    },
    isPagination: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      moment: moment,
      currentPage: 1,
      friendIds: []
    };
  },

  beforeMount() {
    this.fetchFriendStatistics();
  },

  watch: {
    currentPage(page) {
      this.setQueryParams({ page });
      this.fetchFriendStatistics();
    }
  },

  computed: {
    ...mapState('friendStatistic', {
      loading: state => state.loading,
      statistics: state => state.friendStatistics,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    })
  },

  methods: {
    ...mapMutations('friendStatistic', ['setQueryParams']),
    ...mapActions('friendStatistic', ['fetchFriendStatistics'])
  }
};
</script>
<style lang="scss" scoped>
.body-content {
  min-height: 320px;
}
</style>

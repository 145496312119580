<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body mvh-50">
          <div class="d-flex">
            <reorder-folder-left
              type="filter"
              :data="folders"
              :isPc="isPc"
              :selectedFolder="selectedFolderIndex"
              @changeSelectedFolder="onSelectedFolderChanged"
              @submitUpdateFolder="submitUpdateFolder"
              @submitCreateFolder="submitCreateFolder"
              @reorder="onDragFolderEnd"
            />

            <div class="content" :key="contentKey">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <button
                    class="btn btn-primary text-nowrap ml-1 mr-1"
                    data-backdrop="static"
                    data-toggle="modal"
                    data-target="#ModalFilterAdd"
                  >
                  フィルターを追加
                  </button>
                </div>
              </div>
              <div class="mt-2" v-if="curFolder">
                <div class="table-responsive">
                  <table class="table table-centered mb-0">
                    <thead class="thead-light">
                      <tr>
                        <th></th>
                        <th class="mw-400">Name</th>
                        <th class="mw-150">操作</th>
                        <th class="mw-50"></th>
                      </tr>
                    </thead>
                    <draggable
                      tag="tbody"
                      v-model="filtersCopy"
                      @end="onDragFilterEnd"
                      drag-class="dragging-item"
                      ghost-class="destination-item"
                    >
                    <tr v-for="(filter, index) in filtersCopy" :key="index">
                      <td class="drag-icon-cell">
                        <i class="mdi mdi-drag text-secondary"></i>
                      </td>
                      <td class="fw-300 text-truncate">{{ filter.name }}</td>
                      <td>
                        <div class="btn-group">
                          <button
                              type="button"
                              class="btn btn-light btn-sm dropdown-toggle"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              操作 <span class="caret"></span>
                            </button>
                            <div class="dropdown-menu">
                              <a role="button" class="dropdown-item" @click="getListLineFriend(filter.id)">
                                リストライン友達を取得
                              </a>
                              <a role="button" class="dropdown-item" :href="`${rootPath}/user/filters/${filter.id}`">
                                フィルターを詳細
                              </a>
                              <a role="button" class="dropdown-item" :href="`${rootPath}/user/filters/${filter.id}/edit`">
                                フィルターを編集
                              </a>
                              <a
                                role="button"
                                class="dropdown-item"
                                data-toggle="modal"
                                data-target="#modalDeleteFilter"
                                @click="curFilterIndex = index"
                              >
                                フィルターを削除
                              </a>
                            </div>
                        </div>
                      </td>
                      <td>
                          <div class="actions ml-auto mr-2">
                            <button
                              @click.stop="moveFilter(index, 'up')"
                              :class="{ disabled: index === 0 && queryParams.page === 1 }"
                              class="btn btn-light btn-sm action-button"
                              :disabled="index === 0 && queryParams.page === 1"
                            >
                              <i class="dripicons-chevron-up"></i>
                            </button>
                            <button
                              @click.stop="moveFilter(index, 'down')"
                              :class="{ disabled: index === filtersCopy.length - 1 && queryParams.page === totalPages }"
                              class="btn btn-light btn-sm action-button"
                              :disabled="index === filtersCopy.length - 1 && queryParams.page === totalPages"
                            >
                              <i class="dripicons-chevron-down"></i>
                            </button>
                          </div>
                        </td>
                    </tr>
                    </draggable>
                  </table>
                </div>
                <div class="d-flex justify-content-center mt-3 text-center">
                  <b-pagination
                    v-if="totalRows > perPage"
                    v-model="queryParams.page"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="loadPage"
                    aria-controls="my-table"
                  ></b-pagination>
                </div>
                <div class="text-center my-5 font-weight-bold" v-if="!loading && totalRows === 0">シナリオはありません。</div>
              </div>
            </div>
          </div>
        </div>

        <loading-indicator :loading="loading"></loading-indicator>
      </div>
    </div>

    <!-- START: Delete folder modal -->
    <modal-confirm
      title="このフォルダーを削除してもよろしいですか？"
      id="modalDeleteFolder"
      type="delete"
      @confirm="submitDeleteFolder"
    >
      <template v-slot:content v-if="curFolder">
        <span>フォルダー名：{{ curFolder.name }}</span>
      </template>
    </modal-confirm>
    <!-- END: Delete folder modal -->

    <!-- START: Add filter modal -->
    <modal-filter-add :filerFolders="folders" :curFolder="curFolder"  @filter-saved="loadPage"/>
    <!-- END: Add filter modal -->

    <!-- START: Delete filter modal -->
      <modal-confirm
        title="このフィルターを削除してもよろしいですか？"
        id="modalDeleteFilter"
        type="delete"
        @confirm="submitDeleteFilter"
      >
        <template v-slot:content>
          <p v-if="curFilter">
            フィルター名：<b>{{ curFilter.name }}</b>
            <br />
          </p>
          <div class="alert alert-warning">このフィルターを削除すると関連する設定が影響を受ける可能性があります。</div>
        </template>
      </modal-confirm>
      <!-- END: Delete filter modal -->
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import Util from '@/core/util';

export default {
  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH || '',
      loading: true,
      contentKey: 0,
      currentPage: 1,
      filtersCopy: [],
      queryParams: null,
      curFilterIndex: 0,
      selectedFolderIndex: 0
    };
  },
  computed: {
    ...mapGetters('filter', ['getQueryParams']),
    ...mapState('filter', {
      folders: state => state.folders,
      filters: state => state.filters,
      friends: state => state.friends,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage,
      friendMeta: state => state.friendMeta
    }),
    curFolder() {
      return this.folders[this.selectedFolderIndex] || null;
    },
    curFilter() {
      return this.filters[this.curFilterIndex];
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    },
    isPc() {
      return window.innerWidth > 768;
    }
  },

  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },

  async beforeMount() {
    await this.getFolders();
    const folderId = Number.parseInt(Util.getParamFromUrl('folder_id'));
    const index = folderId > 0 ? _.findIndex(this.folders, _ => _.id === folderId) : 0;
    this.onSelectedFolderChanged(index > 0 ? index : 0);
    this.loading = false;
  },

  methods: {
    ...mapMutations('filter', ['setQueryParams', 'updateFilterOrder', 'updateFolderOrder']),
    ...mapActions('filter', [
      'getFolders',
      'deleteFolder',
      'createFolder',
      'updateFolder',
      'reorderFolders',
      'getFilters',
      'deleteFilter',
      'reorderFilters'
    ]),

    forceRerender() {
      this.contentKey++;
    },

    async onDragFolderEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.folders,
        this.reorderFolders,
        this.updateFolderOrder
      );
    },
    async moveFilter(index, direction) {
      this.queryParams.page = await Util.moveItem(
        this.filtersCopy,
        index,
        direction,
        this.reorderFilters.bind(this),
        this.updateFilterOrder.bind(this),
        null,
        this.queryParams.page,
        this.totalPages
      );

      await this.getFilters();
      this.filtersCopy = this.filters;
    },

    async onDragFilterEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.filtersCopy,
        this.reorderFilters.bind(this),
        this.updateFilterOrder.bind(this)
      );

      await this.getFilters();
    },

    onSelectedFolderChanged(index) {
      this.queryParams.page = 1;
      this.selectedFolderIndex = index;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    async submitCreateFolder(folder) {
      const response = await this.createFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの作成は完了しました。');
      } else {
        window.toastr.error('フォルダーの作成は失敗しました。');
      }
    },

    async submitUpdateFolder(folder) {
      const response = await this.updateFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの変更は完了しました。');
      } else {
        window.toastr.error('フォルダーの変更は失敗しました。');
      }
    },

    async submitDeleteFolder() {
      const response = await this.deleteFolder(this.curFolder.id);
      if (response) {
        window.toastr.success('フォルダーの削除は完了しました。');
        this.onSelectedFolderChanged(0);
      } else {
        window.toastr.error('フォルダーの削除は失敗しました。');
      }
    },

    async submitDeleteFilter() {
      const response = await this.deleteFilter(this.curFilter.id);
      if (response) {
        Util.showSuccessThenRedirect('フィルターの削除は完了しました。', location.href);
      } else {
        window.toastr.error('フィルターの削除は失敗しました。');
      }
      this.forceRerender();
    },

    search() {
      this.queryParams.page = 1;
      this.loadPage();
    },

    async loadPage() {
      this.$nextTick(async() => {
        this.loading = true;
        this.setQueryParams(this.queryParams);
        await this.getFilters();
        this.filtersCopy = this.filters;
        this.forceRerender();
        this.loading = false;
      });
    },
    async getListLineFriend(filterId) {
      if (!filterId) return;

      const baseUrl = '/user/friends';
      const url = `${baseUrl}?filterId=${encodeURIComponent(filterId)}`;
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  width: calc(100% - 300px);
}
.dragging-item {
  opacity: 0.8;
  border: 2px solid #008a00 !important;
}
.destination-item {
  opacity: 0.5;
  border: 2px dashed #6a0dad;
  background-color: #d8bfd8;
}
</style>

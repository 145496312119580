export function convertKeysToSnakeCase(obj) {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
    newObj[newKey] = obj[key];
  });

  return newObj;
}

export function convertKeysToSnakeCaseByRecursion(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCaseByRecursion(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
      acc[snakeKey] = convertKeysToSnakeCaseByRecursion(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

export function convertKeysToCamelCase(obj) {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    const newKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    newObj[newKey] = obj[key];
  });

  return newObj;
}

export function convertKeysToCamelCaseByRecursion(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCaseByRecursion(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      acc[camelKey] = convertKeysToCamelCaseByRecursion(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

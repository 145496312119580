import TagAPI from '../api/tag_api';
import FolderAPI from '../api/folder_api';

export const state = {
  folders: [],
  totalRows: 0,
  perPage: 0,
  curPage: 1,
  tags: [],
  queryParams: {
    name_cont: null,
    page: 1,
    folder_id_eq: ''
  }
};

// TODO need refactor
export const mutations = {
  editFolder(state, folder) {
    const old = state.folders.find(_ => _.id === folder.id);
    old.tags_count = folder.tags_count;
    old.tags = folder.tags;
  },

  setFolders(state, folders) {
    state.folders = folders;
  },

  pushFolder(state, folder) {
    folder.tags_count = 0;
    folder.tags = [];
    state.folders.push(folder);
  },

  updateFolder(state, newItem) {
    const item = state.folders.find(item => item.id === newItem.id);
    if (item) {
      item.name = newItem.name;
    }
  },

  deleteFolder(state, id) {
    const index = state.folders.findIndex(_ => _.id === id);
    state.folders.splice(index, 1);
  },

  pushTag(state, tag) {
    tag.friends_count = 0;
    state.tags.push(tag);
  },

  updateTag(state, tag) {
    const folder = state.folders.find(_ => _.id === tag.folder_id);
    const oldIndex = folder.tags.findIndex(_ => _.id === tag.id);
    folder.tags.splice(oldIndex, 1, tag);
  },

  setTags(state, tags) {
    state.tags = tags;
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  setQueryParams(state, param) {
    Object.assign(state.queryParams, param);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  async getTags(context, query) {
    try {
      const params = {
        page: state.queryParams.page,
        q: _.omit(state.queryParams, 'page')
      };
      const combinedQuery = { ...query, ...params };
      const res = await TagAPI.list(combinedQuery);

      context.commit('setTags', res.data);
      context.commit('setMeta', res.meta);
    } catch (error) {
      console.log(error);
    }
  },

  async createFolder(context, payload) {
    try {
      const folder = await FolderAPI.create(payload);
      context.commit('pushFolder', folder);
      return folder;
    } catch (error) {
      return null;
    }
  },

  async updateFolder(context, payload) {
    try {
      const folder = await FolderAPI.update(payload);
      context.commit('updateFolder', folder);
      return folder;
    } catch (error) {
      return null;
    }
  },

  async deleteFolder(context, id) {
    try {
      const response = await FolderAPI.delete(id);
      context.commit('deleteFolder', id);
      return response;
    } catch (error) {
      return null;
    }
  },

  async createTag(context, payload) {
    try {
      console.log(3, payload);
      const response = await TagAPI.create(payload);
      console.log(33, response);
      context.commit('pushTag', response);
      console.log(333, response);
      return response;
    } catch (error) {
      return null;
    }
  },

  async updateTag(context, payload) {
    try {
      const response = await TagAPI.update(payload);
      context.commit('updateTag', response);
      return response;
    } catch (error) {
      return null;
    }
  },

  async deleteTag(context, id) {
    try {
      return await TagAPI.delete(id);
    } catch (error) {
      return null;
    }
  },

  async getFolders(context, query) {
    try {
      const params = { type: 'tag', name: state.queryParams.name_cont };
      const combinedQuery = { ...query, ...params };
      const response = await FolderAPI.list(combinedQuery);
      context.commit('setFolders', response);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
};

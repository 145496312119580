<template>
  <div class="d-inline-block chose-actions-presentor align-middle" v-if="actions.length > 0">
    <div class="mw-120 mxw-150 text-truncate chose-actions-presentor-main" v-html="willBeShowedActions"></div>
  </div>
  <div v-else>なし</div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    willBeShowedActions: function() {
      let result = '';
      this.actions.forEach(action => {
        switch (action.type) {
        case 'text':
          result += `テキスト[${action.content.text}]を送信 <br>`;
          break;
        case 'template':
          result += `テンプレート[${action.content.name}]を送信 <br>`;
          break;
        case 'scenario':
          result += `シナリオ[${action.content.title}]を送信 <br>`;
          break;
        case 'email':
          result += `メール通知[${action.content.text}]を送信 <br>`;
          break;
        case 'tag':
          result += 'タグ操作を送信 <br>';
          break;
        // case 'service_review':
        //   result += 'サービス評価フォームを送信 <br>';
        //   break;
        }
      });
      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1366px) {
    .chose-actions-presentor {
      .chose-actions-presentor-main {
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
      }
    }
  }
</style>

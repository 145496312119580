<template>
  <div
    id="ModalFilterAdd"
    ref="ModalFilterAdd"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="info-header-modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" :key="contentKey">
      <div class="modal-content p-3">
        <div class="mb-3" v-if="isShowSaveCopy">
          <label class="font-weight-bold">コピー元</label>
          <template>
            <filter-copy @selectFilter="handleSelectFilter"></filter-copy>
          </template>
        </div>

        <!-- Header -->
        <div class="mb-3">
          <label class="font-weight-bold">管理用の名前 <required-mark /></label>
          <b-form-input
            v-model="filterName"
            placeholder="管理用の名前を入力"
            class="w-50"
            name="filterName"
            data-vv-as="管理用の名前"
            v-validate="'required|max:255'"
          ></b-form-input>
          <span v-if="errors.filterName" class="text-danger">
            {{ errors.filterName }}
          </span>

          <label class="font-weight-bold mt-2" v-if="!isShowSaveCopy">フォルダー <required-mark /></label>
          <b-form-select
            v-if="!isShowSaveCopy"
            :options="folderOptions"
            class="w-50"
            v-model="selectedFolderId"
            v-validate="'required'"
            name="selectedFolderId"
            data-vv-as="フォルダー"
          ></b-form-select>
          <span v-if="errors.selectedFolderId" class="text-danger">
            {{ errors.selectedFolderId }}
          </span>
        </div>

        <!-- Conditions -->
        <div>
          <b-card
            v-for="(conditionGroup, index) in conditionGroups"
            :key="index"
            class="mb-4"
          >
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <h5 class="font-weight-bold mr-3">
                    {{
                      conditionGroup.type === "and"
                        ? '"すべて満たす" 必要がある条件 (and条件)'
                        : '"いずれか1つ以上満たす" 必要がある条件 (or条件)'
                    }}
                  </h5>
                  <b-form-select
                    class="w-25"
                    v-model="conditionGroup.type"
                    :options="conditionTypeOptions"
                    @change="(value) => updateConditionType(index, value)"
                  ></b-form-select>
                </div>
                <div v-if="conditionGroups.length > 1">
                  <b-button variant="outline-danger" class="btn-sm d-flex justify-content-center align-items-center" @click="removeConditionGroup(index)">×</b-button>
                </div>
              </div>
            </template>

            <!-- Selected Conditions -->
            <div v-for="(selected, selectedIndex) in conditionGroup.selectedConditions" :key="selectedIndex" class="w-100 my-2">
              <div class="d-flex justify-content-between align-items-center border p-2">
                <span>{{ selected.text }}</span>
                <b-button
                  variant="outline-danger"
                  class="btn-sm d-flex justify-content-center align-items-center"
                  @click="removeCondition(index, selectedIndex)"
                >
                  ×
                </b-button>
              </div>

              <div class="d-flex flex-column align-items-start border w-100 p-2">
                <!-- Name hoặc Personal Note -->
                <div v-if="selected.keyName === 'name' || selected.keyName === 'personal_note'" class="w-100">
                  <label class="font-weight-bold mb-1">{{ selected.keyName === 'name' ? '名前' : '個人メモ' }} <required-mark /></label>
                  <b-form-input
                    v-model="selected.inputValue"
                    placeholder="名前または個人メモを入力"
                    class="w-100 mb-2"
                    v-validate="'required|max:255'"
                    :name="`${selected.keyName}-${index}-${selectedIndex}`"
                  ></b-form-input>
                  <span v-if="errors[`${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                    名前は必須項目です。
                  </span>

                  <!-- Condition Operator -->
                  <ConditionOperator
                    :operator.sync="selected.operator"
                    :options="getOperatorOptions(selected.keyName)"
                    :name="`operator-${selected.keyName}-${index}-${selectedIndex}`"
                    :error-message="errors[`operator-${selected.keyName}-${index}-${selectedIndex}`]"
                  />
                </div>

                <!-- Tag hoặc Response Mark -->
                <div v-else-if="selected.keyName === 'tag' || selected.keyName === 'mark'" class="w-100">
                  <template v-if="selected.keyName === 'tag'">
                    <!-- Form Select -->
                    <label class="font-weight-bold mb-1">タイプの選択 <required-mark /></label>
                    <b-form-select
                      v-model="selected.folder"
                      :options="[
                        { value: 'folder', text: 'フォルダー' },
                        { value: 'tag', text: 'タグ' }
                      ]"
                      class="w-50 mb-2"
                      v-validate="'required'"
                      :name="`folder-${selected.keyName}-${index}-${selectedIndex}`"
                    ></b-form-select>
                    <span v-if="errors[`folder-${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                      タイプを選択してください。
                    </span>
                    <template v-if="selected.folder === 'tag'">
                      <label class="font-weight-bold mb-1">タグの選択 <required-mark /></label>
                      <multiselect
                        v-model="selected.selectedTagOptions"
                        :options="availableTagOptions"
                        :multiple="true"
                        :searchable="true"
                        label="text"
                        track-by="value"
                        placeholder="タグオプションを選択してください"
                        class="w-100 mb-2"
                        v-validate="'required'"
                        :name="`tag-${selected.keyName}-${index}-${selectedIndex}`"
                        @search-change="(text) => handleSearch('tag', text)"
                      ></multiselect>
                      <span v-if="errors[`tag-${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                        タグを選択してください。
                      </span>
                    </template>

                    <template v-if="selected.folder === 'folder'">
                      <label class="font-weight-bold mb-1">フォルダーの選択 <required-mark /></label>
                      <multiselect
                        v-model="selected.selectedFolderOptions"
                        :options="availableFolderOptions"
                        :multiple="true"
                        :searchable="true"
                        label="text"
                        track-by="value"
                        placeholder="フォルダーを選択してください"
                        class="w-100 mb-2"
                        v-validate="'required'"
                        :name="`folder-${selected.keyName}-${index}-${selectedIndex}`"
                        @search-change="(text) => handleSearch('folder', text)"
                      ></multiselect>
                      <span v-if="errors[`folder-${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                        フォルダーを選択してください。
                      </span>
                    </template>
                  </template>

                  <template v-if="selected.keyName === 'mark'">
                    <label class="font-weight-bold mb-1">レスポンスマーク <required-mark /></label>
                    <multiselect
                      v-model="selected.selectedMarkOptions"
                      :options="availableMarkOptions"
                      :multiple="true"
                      :searchable="true"
                      label="text"
                      track-by="value"
                      placeholder="レスポンスマークを選択してください"
                      class="w-100 mb-2"
                      v-validate="'required'"
                      :name="`selectedMarkOptions-${selected.keyName}-${index}-${selectedIndex}`"
                      @search-change="(text) => handleSearch('mark', text)"
                    ></multiselect>
                    <span v-if="errors[`selectedMarkOptions-${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                      レスポンスマークを選択してください。
                    </span>
                  </template>

                  <!-- Condition Operator -->
                  <ConditionOperator
                    :operator.sync="selected.operator"
                    :options="getOperatorOptions(selected.keyName)"
                    :name="`operator-${selected.keyName}-${index}-${selectedIndex}`"
                    :error-message="errors[`operator-${selected.keyName}-${index}-${selectedIndex}`]"
                  />
                </div>

                <!-- Friend Information -->
                <div v-else-if="selected.keyName === 'infor_line_friend'" class="w-100 d-flex flex-column">
                  <label class="font-weight-bold mb-1">友たち情報 <required-mark /></label>
                  <multiselect
                    v-model="selected.selectedVariableOptions"
                    :options="availableVariableOptions"
                    :multiple="false"
                    :searchable="true"
                    label="text"
                    track-by="value"
                    placeholder="オプションを選択してください"
                    class="w-100 mb-2"
                    v-validate="'required'"
                    :name="`selectedVariableOptions-${selected.keyName}-${index}-${selectedIndex}`"
                    @search-change="(text) => handleSearch('variable', text)"
                    @input="onSelectValue($event, selected)"
                  ></multiselect>
                  <span v-if="errors[`selectedVariableOptions-${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                    友だち情報を入力してください。
                  </span>

                  <template v-if="selected.variableType === 'text'">
                    <label class="font-weight-bold mb-1">テキスト <required-mark /></label>
                    <b-form-input
                      v-model="selected.inputValue"
                      placeholder="名前または個人メモを入力"
                      class="w-100 mb-2"
                      v-validate="'required|max:255'"
                      :name="`${selected.keyName}-${index}-${selectedIndex}`"
                    ></b-form-input>
                    <span v-if="errors[`${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                      名前は必須項目です。
                    </span>
                  </template>

                  <template v-else-if="selected.variableType === 'date'">
                  <label class="font-weight-bold mb-1">日付 <required-mark /></label>
                    <template v-if="selected.operator == 'between' || selected.operator == 'not_between'">
                      <daterange-picker
                        v-model="selected.inputValue"
                        class="w-100 mb-2"
                        :name="`date-${selected.keyName}-${index}-${selectedIndex}`"
                        :start_date.sync="selected.inputValue.startDate" :end_date.sync="selected.inputValue.endDate"
                      ></daterange-picker>
                    </template>
                    <template v-else>
                      <date-picker
                      v-model="selected.inputValue"
                      type="date"
                      placeholder="日付を選択してください"
                      format="YYYY-MM-DD"
                      lang="ja"
                      input-class="form-control w-100 mb-2"
                      v-validate="'required'"
                      :name="`date-${selected.keyName}-${index}-${selectedIndex}`"
                    />
                    </template>
                    <span v-if="errors[`date-${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                      日付を選択してください。
                    </span>
                  </template>

                  <template v-else-if="selected.variableType === 'datetime'">
                    <label class="font-weight-bold mb-1">日時 <required-mark /></label>
                    <template v-if="selected.operator == 'between' || selected.operator == 'not_between'">
                      <daterange-picker
                        v-model="selected.inputValue"
                       class="w-100 mb-2"
                        :name="`datetime-${selected.keyName}-${index}-${selectedIndex}`"
                        :start_date.sync="selected.inputValue" :end_date.sync="selected.secondValue"
                      ></daterange-picker>
                    </template>
                    <template v-else>
                      <date-picker
                        v-model="selected.inputValue"
                        type="datetime"
                        placeholder="日時を選択してください"
                        format="YYYY-MM-DD HH:mm:ss"
                        lang="ja"
                        input-class="form-control w-100 mb-2"
                        v-validate="'required'"
                        :name="`datetime-${selected.keyName}-${index}-${selectedIndex}`"
                      />
                    </template>
                    <span v-if="errors[`datetime-${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                      日時を選択してください。
                    </span>
                  </template>

                  <template v-else-if="selected.variableType === 'time'">
                    <label class="font-weight-bold mb-1">時間 <required-mark /></label>
                    <date-picker
                      v-model="selected.inputValue"
                      type="time"
                      placeholder="時間を選択してください"
                      format="HH:mm:ss"
                      lang="ja"
                      input-class="form-control w-100 mb-2"
                      v-validate="'required'"
                      :name="`time-${selected.keyName}-${index}-${selectedIndex}`"
                    />
                    <span v-if="errors[`time-${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                      時間を選択してください。
                    </span>
                  </template>

                  <label class="font-weight-bold mb-1">条件 <required-mark /></label>
                  <b-form-select
                    v-model="selected.operator"
                    :options="selected.operatorOptions"
                    class="w-50"
                    v-validate="'required'"
                    :name="`operator-${selected.keyName}-${index}-${selectedIndex}`"
                  ></b-form-select>
                  <span v-if="errors[`operator-${selected.keyName}-${index}-${selectedIndex}`]" class="text-danger">
                    条件を選択してください。
                  </span>
                </div>

                <!-- Friend Registration Date -->
                <div v-else-if="selected.keyName === 'friend_registration_date'" class="w-100">
                  <label class="font-weight-bold mb-1">友だち登録日 <required-mark /></label>
                  <daterange-picker
                    v-model="selected.dateRange"
                    class="w-100 mb-2"
                    :name="`friend_registration_date-${index}-${selectedIndex}`"
                    :start_date.sync="selected.dateRange.startDate" :end_date.sync="selected.dateRange.endDate"
                  ></daterange-picker>
                  <span v-if="errors[`friend_registration_date-${index}-${selectedIndex}`]" class="text-danger">
                    {{ errors[`friend_registration_date-${index}-${selectedIndex}`] }}
                  </span>

                  <!-- Condition Operator -->
                  <ConditionOperator
                    :operator.sync="selected.operator"
                    :options="getOperatorOptions(selected.keyName)"
                    :name="`operator-${selected.keyName}-${index}-${selectedIndex}`"
                    :error-message="errors[`operator-${selected.keyName}-${index}-${selectedIndex}`]"
                  />
                </div>
              </div>
            </div>

            <!-- Available Conditions -->
            <div class="d-flex flex-wrap">
              <b-button
                v-for="(condition, keyIndex) in conditionGroup.conditions"
                :key="keyIndex"
                variant="outline-secondary"
                class="m-1"
                @click="addCondition(index, condition)"
              >
                {{ condition.text }}
              </b-button>
            </div>
          </b-card>
        </div>

        <div class="text-center mb-3">
          <b-button
            class="w-50"
            variant="outline-primary"
            @click="addConditionGroup"
          >
          条件を追加する
          </b-button>
        </div>

        <!-- Block options -->
        <div class="d-flex justify-content-between mb-3 input-group-equal">
          <div>
            <label>ブロックされた人 <required-mark /></label>
            <b-form-select name="showBlockedUsers" v-model="showBlockedUsers" :options="booleanOptions" class="w-100" v-validate="'required'"></b-form-select>
            <span v-if="errors.showBlockedUsers" class="text-danger">
              {{ errors.showBlockedUsers }}
            </span>
          </div>
          <div>
            <label>ブロック・非表示にした人 <required-mark /></label>
            <b-form-select name="showHiddenUsers" v-model="showHiddenUsers" :options="booleanOptions" class="w-100" v-validate="'required'"></b-form-select>
            <span v-if="errors.showHiddenUsers" class="text-danger">
              {{ errors.showHiddenUsers }}
            </span>
          </div>
          <div>
            <label>表示順 <required-mark /></label>
            <b-form-select name="selectedDisplayOrder" v-model="selectedDisplayOrder" :options="DisplayOrderOptions" class="w-100" v-validate="'required'"></b-form-select>
            <span v-if="errors.selectedDisplayOrder" class="text-danger">
              {{ errors.selectedDisplayOrder }}
            </span>
          </div>
          <div>
            <label>表示数 <required-mark /></label>
            <b-form-select name="" v-model="selectedDisplayCount" :options="displayOptions" class="w-100" v-validate="'required'"></b-form-select>
            <span v-if="errors.selectedDisplayCount" class="text-danger">
              {{ errors.selectedDisplayCount }}
            </span>
          </div>
        </div>

        <!-- Footer -->
        <div class="text-center d-flex justify-content-center">
          <b-button variant="success" class="px-5" @click="checkValidation(true)">この条件で保存</b-button>
          <b-button variant="primary" class="px-5 ml-3" @click="checkValidation(false)" v-if="isShowSaveDraft">条件で検索</b-button>
          <b-button
            id="close-modal-btn"
            variant="danger"
            class="ml-3"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            閉じる
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { convertKeysToSnakeCaseByRecursion } from '../../core/convertKeys.js';

export default {
  props: {
    filerFolders: {
      type: Array,
      required: true
    },
    curFolder: {
      type: Object,
      required: true
    },
    isBoardcast: {
      type: Boolean,
      default: false
    },
    filterData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      filterName: '',
      selectedFolderId: null,
      selectedDisplayOrder: 'asc',
      selectedDisplayCount: 10,
      showBlockedUsers: false,
      showHiddenUsers: false,
      conditionGroups: [],
      booleanOptions: this.BooleanOptions,
      orderOptions: this.OrderOptions,
      displayCountOptions: this.DisplayCountOptions,
      blockOptions: this.BlockOptions,
      displayOptions: this.DisplayCountOptions,
      conditionTypeOptions: this.ConditionTypeOptions,
      debounceTimeout: null,
      contentKey: 0,
      isShowSaveDraft: false,
      isShowSaveCopy: false,
      isCheckValidField: true,
      errors: {},
      errorDate: false
    };
  },
  computed: {
    ...mapState('tag', {
      folders: state => state.folders,
      tags: state => state.tags
    }),
    ...mapState('mark', {
      marks: state => state.marks
    }),
    ...mapState('variable', {
      variables: state => state.variables
    }),
    folderOptions() {
      return this.filerFolders.map(({ id, name }) => ({
        value: id,
        text: name
      }));
    },
    availableTagOptions() {
      return this.tags.map(({ id, name }) => ({
        value: id,
        text: name
      }));
    },
    availableMarkOptions() {
      return this.marks.map(({ id, name }) => ({
        value: id,
        text: name
      }));
    },
    availableFolderOptions() {
      return this.folders.map(({ id, name }) => ({
        value: id,
        text: name
      }));
    },
    availableVariableOptions() {
      return this.variables.map(({ id, name, type }) => ({
        value: id,
        text: name,
        type: type
      }));
    }
  },
  async beforeMount() {
    await this.getTags({ per: 10 });
    await this.getMarks({ per: 10 });
    await this.getVariables({ per: 10 });
    await this.getFolders();
  },
  watch: {
    curFolder: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.id) {
          this.selectedFolderId = newVal.id;
        }
      }
    }
  },
  methods: {
    ...mapActions('filter', ['createFilter']),
    ...mapActions('tag', ['getTags', 'getFolders']),
    ...mapActions('mark', ['getMarks']),
    ...mapMutations('tag', { setTagQueryParams: 'setQueryParams' }),
    ...mapMutations('mark', { setMarkQueryParams: 'setQueryParams' }),
    ...mapMutations('variable', ['setQueryParams']),
    ...mapActions('variable', ['getVariables']),
    updateConditionType(index, newType) {
      this.conditionGroups[index].type = newType;
    },
    onSelectValue(value, selected) {
      if (!value && !selected) return;
      selected.selectedVariableOptions = value;
      selected.variableType = value.type;

      const type = value.type;

      if (type === 'text') {
        selected.operatorOptions = [
          { value: '=', text: '一致する' },
          { value: '!=', text: '一致しない' },
          { value: 'like', text: '部分一致' },
          { value: 'not_like', text: '部分不一致' }
        ];
      } else if (type === 'date' || type === 'datetime') {
        selected.operatorOptions = [
          { value: 'between', text: '指定範囲内' },
          { value: 'not_between', text: '指定範囲外' },
          { value: '=', text: '一致する' },
          { value: '!=', text: '一致しない' }
        ];
      } else if (type === 'time') {
        selected.operatorOptions = [
          { value: '=', text: '一致する' },
          { value: '!=', text: '一致しない' },
          { value: 'greater_than', text: 'より大きい' },
          { value: 'less_than', text: 'より小さい' }
        ];
      } else {
        selected.operatorOptions = [
          { value: '=', text: '一致する' },
          { value: '!=', text: '一致しない' }
        ];
      }
    },
    addCondition(groupIndex, condition) {
      const group = this.conditionGroups[groupIndex];
      const newCondition = {
        keyName: condition.keyName,
        text: condition.text,
        inputValue: '',
        variableType: '',
        selectedTagOptions: [],
        selectedMarkOptions: [],
        selectedFolderOptions: [],
        selectedVariableOptions: [],
        folder: 'tag',
        fileType: '',
        dateRange: { startDate: null, endDate: null },
        operator: ''
      };
      group.selectedConditions.push(newCondition);
    },
    removeCondition(groupIndex, selectedIndex) {
      const group = this.conditionGroups[groupIndex];
      group.selectedConditions.splice(selectedIndex, 1);
    },
    addConditionGroup() {
      this.conditionGroups.push({
        type: 'and',
        conditions: [...this.DisplayConditions],
        selectedConditions: []
      });
    },
    removeConditionGroup(index) {
      if (this.conditionGroups.length > 1) {
        this.conditionGroups.splice(index, 1);
      }
    },
    closeModal() {
      this.resetData();
    },
    async showModal() {
      this.isShowSaveDraft = true;
      await this.loadSavedFilters(this.filterData);
    },
    showModalCopy() {
      this.isShowSaveCopy = true;
      if (this.filterData) {
        console.log(this.filterData);
        this.loadSavedFilters(this.filterData);
      }
    },
    handleSelectFilter(filter) {
      if (!filter) return;

      const convertedFilter = {
        folderId: filter.folder_id || null,
        name: filter.name || '',
        displayOrder: filter.display_order || 'asc',
        displayCount: filter.display_count || 10,
        showBlockedUsers: !!filter.show_blocked_users,
        showHiddenUsers: !!filter.show_hidden_users,
        conditions: this.formatConditions(filter.conditions)
      };

      this.loadSavedFilters(convertedFilter);
    },

    formatConditions(parsedConditions) {
      if (!Array.isArray(parsedConditions)) return [];

      return parsedConditions.map(group => ({
        type: group.type || 'and',
        selectedConditions: (group.selected_conditions || []).map(condition => ({
          keyName: condition.key_name || '',
          operator: condition.operator || '=',
          secondValue: condition.second_value || '',
          text: condition.text || '',
          type: condition.type || '',
          value: condition.value || '',
          items: condition.items || [],
          folder: condition.folder || ''
        }))
      }));
    },
    loadSavedFilters(filter) {
      if (!filter) {
        this.resetData();
        return;
      }

      const {
        name = '',
        folderId = null,
        displayOrder = 'asc',
        displayCount = 10,
        showBlockedUsers = false,
        showHiddenUsers = false,
        conditions = []
      } = filter;

      this.filterName = name;
      this.selectedFolderId = folderId;
      this.selectedDisplayOrder = displayOrder;
      this.selectedDisplayCount = displayCount;
      this.showBlockedUsers = !!showBlockedUsers;
      this.showHiddenUsers = !!showHiddenUsers;

      this.conditionGroups = conditions.map((group) => ({
        type: group.type || 'and',
        conditions: [...this.DisplayConditions],
        selectedConditions: (group.selectedConditions || group.selected_conditions || []).map((selected) => {
          const updatedCondition = {
            keyName: selected.keyName || selected.key_name || '',
            operator: selected.operator || '=',
            value: selected.value || '',
            secondValue: selected.secondValue || selected.second_value || '',
            text: selected.text || '',
            type: selected.type || '',
            items: selected.items || [],
            folder: selected.folder || ''
          };

          switch (updatedCondition.keyName) {
          case 'name':
          case 'personal_note':
            updatedCondition.inputValue = updatedCondition.value;
            break;
          case 'tag':
            updatedCondition.folder = updatedCondition.folder || 'tag';
            if (updatedCondition.folder === 'folder') {
              updatedCondition.selectedFolderOptions = updatedCondition.items;
            } else {
              updatedCondition.selectedTagOptions = updatedCondition.items;
            }
            break;

          case 'mark':
            updatedCondition.selectedMarkOptions = updatedCondition.items;
            break;

          case 'infor_line_friend':
            if (updatedCondition.type === 'text') {
              updatedCondition.operatorOptions = [
                { value: '=', text: '一致する' },
                { value: '!=', text: '一致しない' },
                { value: 'like', text: '部分一致' },
                { value: 'not_like', text: '部分不一致' }
              ];
              updatedCondition.inputValue = updatedCondition.value;
            } else if (['date', 'datetime'].includes(updatedCondition.type)) {
              updatedCondition.operatorOptions = [
                { value: 'between', text: '指定範囲内' },
                { value: 'not_between', text: '指定範囲外' },
                { value: '=', text: '一致する' },
                { value: '!=', text: '一致しない' }
              ];
              if (['between', 'not_between'].includes(updatedCondition.operator)) {
                updatedCondition.inputValue = {
                  startDate: updatedCondition.value || null,
                  endDate: updatedCondition.secondValue || null
                };
                console.log(updatedCondition.inputValue);
              } else {
                const datetime = new Date(updatedCondition.value);
                updatedCondition.inputValue = datetime;
              }
            } else if (updatedCondition.type === 'time') {
              updatedCondition.operatorOptions = [
                { value: '=', text: '一致する' },
                { value: '!=', text: '一致しない' },
                { value: 'greater_than', text: 'より大きい' },
                { value: 'less_than', text: 'より小さい' }
              ];
              const time = new Date(`1970-01-01T${updatedCondition.value}`);
              updatedCondition.inputValue = time;
            } else {
              updatedCondition.operatorOptions = [
                { value: '=', text: '一致する' },
                { value: '!=', text: '一致しない' }
              ];
            }

            if (Array.isArray(updatedCondition.items) && updatedCondition.items.length > 0) {
              updatedCondition.selectedVariableOptions = updatedCondition.items.map((item) => {
                const matchedOption = this.availableVariableOptions.find(
                  (option) => option.value === item.value
                );

                if (matchedOption) {
                  return { ...matchedOption, type: updatedCondition.type || '' };
                }

                return { ...item, type: updatedCondition.type || '' };
              });
            } else {
              updatedCondition.selectedVariableOptions = [];
            }

            updatedCondition.variableType = updatedCondition.type || '';
            break;

          case 'friend_registration_date':
            updatedCondition.dateRange = {
              startDate: updatedCondition.value || null,
              endDate: updatedCondition.secondValue || null
            };
            break;

          default:
            break;
          }

          return updatedCondition;
        })
      }));
    },
    resetData() {
      this.filterName = '';
      this.selectedFolderId = this.curFolder ? this.curFolder.id : null;
      this.displayOrder = 'asc';
      this.displayCount = 50;
      this.showBlockedUsers = false;
      this.showHiddenUsers = false;
      this.conditionGroups = [];
    },
    getOperatorOptions(keyName) {
      const commonOperators = {
        default: [
          { value: '=', text: '一致する' },
          { value: '!=', text: '一致しない' }
        ],
        text: [
          { value: 'like', text: '部分一致' },
          { value: 'not_like', text: '部分不一致' }
        ],
        multiple: [
          { value: 'in', text: '含まれる' },
          { value: 'not_in', text: '含まれない' }
        ],
        range: [
          { value: 'between', text: '指定範囲内' },
          { value: 'not_between', text: '指定範囲外' }
        ]
      };

      const keyNameSpecific = {
        name: [...commonOperators.default, ...commonOperators.text],
        personal_note: [...commonOperators.default, ...commonOperators.text],
        tag: commonOperators.multiple,
        mark: commonOperators.multiple,
        friend_registration_date: commonOperators.range,
        infor_line_friend: commonOperators.default
      };

      return keyNameSpecific[keyName] || commonOperators.default;
    },
    async handleSearch(type, text) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(async() => {
        try {
          let queryParams, fetchFunction, mapFunction;

          switch (type) {
          case 'tag':
            queryParams = this.setTagQueryParams;
            fetchFunction = this.getTags;
            mapFunction = (item) => ({
              value: item.id,
              text: item.name
            });
            break;
          case 'folder':
            queryParams = this.setTagQueryParams;
            fetchFunction = this.getFolders;
            mapFunction = (item) => ({
              value: item.id,
              text: item.name
            });
            break;
          case 'mark':
            queryParams = this.setMarkQueryParams;
            fetchFunction = this.getMarks;
            mapFunction = (item) => ({
              value: item.id,
              text: item.name
            });
            break;
          case 'variable':
            queryParams = this.setVariableQueryParams;
            fetchFunction = this.getVariables;
            mapFunction = (item) => ({
              value: item.id,
              text: item.name
            });
            break;
          default:
            throw new Error('Invalid search type');
          }

          queryParams({ name_cont: text });
          await fetchFunction({ per: 10 });

          switch (type) {
          case 'tag':
            this.availableTagOptions = this.tags.map(mapFunction);
            break;
          case 'folder':
            this.availableFolderOptions = this.folders.map(mapFunction);
            break;
          case 'mark':
            this.availableMarkOptions = this.marks.map(mapFunction);
            break;
          case 'variable':
            this.availableVariableOptions = this.variables.map(mapFunction);
            break;
          }
        } catch (error) {
          console.error(`Error fetching ${type}s:`, error);
        }
      }, 300);
    },
    async checkValidation(isCheck) {
      this.isCheckValidField = isCheck;
      this.errors = {};
      let hasErrors = false;

      const isValid = await this.$validator.validate();
      if (!isValid) {
        const errorMessages = {};
        this.$validator.fields.items.forEach((field) => {
          if (!this.isCheckValidField && (['filterName', 'selectedFolderId'].includes(field.name))) {
            return;
          }

          if (this.$validator.errors.has(field.name)) {
            errorMessages[field.name] = this.$validator.errors.first(field.name);
            hasErrors = true;
          }
        });
        this.errors = errorMessages;
      }

      this.conditionGroups.forEach((group, groupIndex) => {
        group.selectedConditions.forEach((condition, conditionIndex) => {
          if (condition.keyName === 'friend_registration_date') {
            const errorKey = `friend_registration_date-${groupIndex}-${conditionIndex}`;
            if (!condition.dateRange || !condition.dateRange.startDate || !condition.dateRange.endDate) {
              this.$set(this.errors, errorKey, '友だち登録日を選択してください。');
              hasErrors = true;
            } else {
              this.$delete(this.errors, errorKey);
            }
          }
        });
      });

      if (hasErrors) {
        window.toastr.error('入力した情報を確認してください。');
      } else {
        await this.saveFilter();
      }
    },
    async saveFilter() {
      const data = {
        id: this.filter_id,
        folderId: this.selectedFolderId,
        name: this.filterName,
        displayOrder: this.selectedDisplayOrder,
        displayCount: this.selectedDisplayCount,
        showBlockedUsers: this.showBlockedUsers,
        showHiddenUsers: this.showHiddenUsers,
        conditions: this.conditionGroups.map(group => ({
          type: group.type,
          selectedConditions: group.selectedConditions.map(selected => {
            const condition = {
              keyName: selected.keyName,
              text: selected.text,
              operator: selected.operator || ''
            };

            switch (selected.keyName) {
            case 'name':
            case 'personal_note':
              condition.value = selected.inputValue || '';
              condition.operator = selected.operator || '=';
              break;

            case 'tag':
              condition.items = selected.folder === 'folder' ? selected.selectedFolderOptions : selected.selectedTagOptions || [];
              condition.folder = selected.folder;
              condition.operator = selected.operator || 'in';
              break;

            case 'mark':
              condition.items = selected.selectedMarkOptions || [];
              condition.operator = selected.operator || 'in';
              break;

            case 'infor_line_friend':
              if (['date', 'datetime'].includes(selected.variableType)) {
                if (['between', 'not_between'].includes(selected.operator)) {
                  condition.value = this.convertValueDateTime(selected.inputValue.startDate, selected.variableType);
                  condition.secondValue = this.convertValueDateTime(selected.inputValue.endDate, selected.variableType);
                } else {
                  condition.value = this.convertValueDateTime(selected.inputValue, selected.variableType);
                }
              } else if (selected.variableType === 'time') {
                condition.value = this.convertValueDateTime(selected.inputValue, selected.variableType);
              } else {
                condition.value = selected.inputValue || '';
              };
              condition.items = Array.isArray(selected.selectedVariableOptions)
                ? selected.selectedVariableOptions
                : selected.selectedVariableOptions
                  ? [selected.selectedVariableOptions]
                  : [];
              condition.type = selected.variableType || '';
              condition.operator = selected.operator || '=';
              break;

            case 'friend_registration_date': {
              const formatDate = (date) => {
                if (!date) return null;
                const d = new Date(date);
                return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
              };

              condition.value = formatDate(selected.dateRange.startDate);
              condition.secondValue = formatDate(selected.dateRange.endDate);

              condition.operator = selected.operator || 'between';
              break;
            }

            default:
              condition.operator = selected.operator || '';
              break;
            }

            return condition;
          })
        }))
      };
      data.conditions = convertKeysToSnakeCaseByRecursion(data.conditions);

      try {
        let responseId = null;

        if (this.isCheckValidField) {
          if (!this.isShowSaveCopy) {
            const response = await this.createFilter(data);
            if (!response) throw new Error('保存に失敗しました');
            responseId = response.id;
          }
        }
        this.$emit('filter-saved', { ...data, id: responseId });
        window.toastr.success('フィルターが正常に保存されました');
        const closeModalButton = document.getElementById('close-modal-btn');
        if (closeModalButton) closeModalButton.click();
      } catch (error) {
        console.error('Error saving filter:', error);
        window.toastr.error('フィルターの保存に失敗しました');
      }
    },
    convertValueDateTime(value, type = 'datetime') {
      const date = new Date(value);

      if (isNaN(date.getTime())) {
        console.error('Invalid date value:', value);
        return '';
      }

      switch (type) {
      case 'date':
        return date.toISOString().split('T')[0];
      case 'datetime':
        return date.toISOString().replace('Z', '');
      case 'time':
        return date.toLocaleTimeString('en-US', { hour12: false });
      default:
        return value;
      }
    }
  }
};
</script>

<style scoped>
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.font-weight-bold {
  font-weight: bold;
}

.input-group-equal > div {
  flex: 1;
  margin-right: 15px;
}

.input-group-equal > div:last-child {
  margin-right: 0;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-close:hover {
  background-color: darkred;
}
</style>


import DataTransferAPI from '../api/data_transfer_api';

export const state = {
  totalRows: 0,
  perPage: 0,
  curPage: 1,
  dataTransfers: [],
  queryParams: {
    page: 1,
    per: 15
  }
};

export const mutations = {
  setDataTransfers(state, dataTransfers) {
    state.dataTransfers = dataTransfers;
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  setQueryParams(state, param) {
    Object.assign(state.queryParams, param);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  async getDataTransfers(context, query) {
    try {
      const params = {
        page: state.queryParams.page,
        per: state.queryParams.per,
        q: _.omit(state.queryParams, 'page', 'per')
      };
      const combinedQuery = { ...query, ...params };
      const res = await DataTransferAPI.list(combinedQuery);

      context.commit('setDataTransfers', res.data);
      context.commit('setMeta', res.meta);
    } catch (error) {
      console.log(error);
    }
  },

  async getDataTransfer(context, code) {
    try {
      return await DataTransferAPI.get(code);
    } catch (error) {
      return null;
    }
  },

  async createDataTransfer(context, payload) {
    try {
      const response = await DataTransferAPI.create(payload);
      return response;
    } catch (error) {
      return null;
    }
  },

  async transfer(context, code) {
    try {
      const response = await DataTransferAPI.transfer(code);
      return response;
    } catch (error) {
      return null;
    }
  }
};

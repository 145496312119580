<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6 mb-2">
          <label>自分が元データ</label>
          <a class="btn btn-primary" href="/user/data_transfers/new">引き継ぎコード</a>
        </div>
        <div class="col-lg-6">
          <label>自分が移行先</label>
          <div class="row m-0">
            <div class="col-8 p-0">
              <input v-model.trim="codeTransfer" placeholder="引き継ぎコードを入力" class="form-control rounded-0" type="text">
            </div>
            <div class="col-auto p-0">
              <a :href="codeTransfer ? '/user/data_transfers/' + codeTransfer : '#'" class="btn btn-primary rounded-0 text-white">確認画面へ</a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <table class="table table-centered mb-0">
            <thead>
              <tr>
                <th>登録日時</th>
                <th>状態</th>
                <th>期限切れ</th>
                <th>転送された</th>
                <th>変換コード</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dataTransfer, index) in dataTransfers" :key="index">
                <td>
                  {{ formattedDatetime(dataTransfer.created_at) }}
                </td>
                <td>
                  <button class="btn btn-xs btn-secondary" :class="{ 'btn-success': isValid(dataTransfer) }">
                    {{ isValid(dataTransfer) ? '利用可能' : '期限が切れた' }}
                  </button>
                </td>
                <td>
                  {{ formattedDatetime(dataTransfer.expired) }}
                </td>
                <td>
                  {{ dataTransfer.transferred_at && formattedDatetime(dataTransfer.transferred_at) }}
                </td>
                <td>
                  <button
                    :disabled="!isValid(dataTransfer)"
                    class="btn btn-sm btn-secondary"
                    v-clipboard="dataTransfer.code"
                    v-clipboard:success="clipboardSuccessHandler"
                  >コピー</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="totalRows === 0" class="text-center my-5"><b>変換データがありません</b></div>
          <div class="d-flex justify-content-center mt-1" v-if="totalRows > perPage">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <loading-indicator :loading="loading"></loading-indicator>
  </div>
</template>
<script>
import Util from '@/core/util';
import moment from 'moment';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      loading: true,
      currentPage: 1,
      codeTransfer: null,
      formattedDatetime: Util.formattedDatetime
    };
  },

  computed: {
    ...mapState('dataTransfer', {
      meta: state => state.meta,
      dataTransfers: state => state.dataTransfers,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    })
  },

  async beforeMount() {
    this.loading = true;
    await this.fetchData();
    this.loading = false;
  },

  watch: {
    currentPage(page) {
      this.setQueryParams({ page });
      this.fetchData();
    }
  },

  methods: {
    ...mapMutations('dataTransfer', ['setQueryParams']),
    ...mapActions('dataTransfer', ['getDataTransfers']),

    async fetchData() {
      this.loading = true;
      await this.getDataTransfers();
      this.loading = false;
    },

    isValid(data) {
      return moment() < moment(data.expired) && data.status === 'active';
    },

    clipboardSuccessHandler() {
      window.toastr.success('変換コードがコピーされました');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="channel-friend-detail card chat-panel" v-if="activeChannel">
    <div class="card-header d-block d-xl-none">
      <a class="icon-fs" @click="hideUserDetail()"><i class="uil-multiply"></i></a>
    </div>
    <div class="card-body" :class="{ 'overflow-auto': !editing }">
      <div class="text-right">
        <div v-if="isAdmin && !editing" @click="editing = true" class="btn btn btn-sm btn-success">編集</div>
        <div class="btn btn btn-sm btn-success" @click="onSave()" v-show="editing">更新</div>
      </div>
      <div class="text-center">
        <img v-if="!editing" v-lazy="getAvatarImgObj(friendData.avatar_url)" class="img-thumbnail avatar-lg rounded-circle" />
        <template v-if="editing">
          <p class="mb-0 text-left"><strong>表示名</strong></p>
            <p class="text-muted mt-1">
            <input
              type="text"
              placeholder="表示名"
              class="form-control"
              v-model.trim="friendData.display_name"
              name="display_name"
              ref="displayName"
              :disabled="!editing"
              v-validate="'max:255'"
              data-vv-as="表示名"
            />
            <error-message :message="errors.first('display_name')"></error-message>
          </p>
        </template>
        <template v-else>
          <h4>
            {{ (friendData.display_name || friendData.line_name) | truncate(15)
            }}<span v-if="friendData.tester" class="ml-1 pt-1 badge badge-warning badge-sm">テスター</span>
          </h4>
        </template>
      </div>

      <div class="mt-3">
        <div class="d-flex align-items-center justify-content-center" v-if="isAdmin && !editing">
          <a :href="detailPath" class="btn btn-primary btn-sm mr-1 ml-auto fw-80">詳細</a>
          <template v-if="friendData.locked">
            <div class="btn btn-sm btn-info mr-auto" data-toggle="modal" data-target="#modalConfirmToggleLocked">
              アンブロック
            </div>
          </template>
          <template v-else>
            <div class="btn btn-sm btn-danger mr-auto" data-toggle="modal" data-target="#modalConfirmToggleLocked">
              ブロック
            </div>
          </template>
        </div>
        <hr v-if="!editing" class="" />
        <div class="channel-info">
          <template v-if="isAdmin" hidden>
            <!-- START: 担当者 -->
            <p class="mt-3 mb-1 font-12">
              <strong v-if="!editing"><i class="uil uil-user"></i> 担当者:</strong>
              <strong v-else>担当者</strong>
            </p>

            <staff-selection
              :class="{ disabled: !editing }"
              :selected="activeChannel.assignee_id"
              @select="onAssignAgent"
            />
            <!-- END: 担当者 -->
          </template>

          <template v-if="editing">
            <p class="mt-3 mb-0"><strong>メモ欄</strong></p>
            <p class="text-muted mt-1">
              <textarea
                rows="2"
                class="form-control"
                v-model.trim="friendData.note"
                name="note"
                :disabled="!editing"
                v-validate="'max:2000'"
                data-vv-as="メモ欄"
              ></textarea>
              <error-message :message="errors.first('note')"></error-message>
            </p>
          </template>
          <template v-else>
            <p class="mt-3 mb-1 font-12">
              <strong><i class="uil uil-notes"></i> メモ欄:</strong>
            </p>
            <p class="text-prewrap max-3-lines">{{ friendData.note || "なし" }}</p>
          </template>

          <template v-if="editing">
            <p class="mt-3 mb-0"><strong>タグ</strong></p>
            <p class="text-muted mt-1">
              <friend-tag :tags="friendData.tags" v-if="!editing"></friend-tag>
              <input-tag :tags="friendData.tags" @input="onSelectTags" :allTags="true" v-if="editing" />
            </p>
          </template>
          <template v-else>
            <p class="mt-3 mb-1 font-12">
              <strong><i class="uil uil-tag"></i> タグ:</strong>
            </p>
            <p><friend-tag :tags="friendData.tags"></friend-tag></p>
          </template>

          <template v-if="!editing">
            <p class="mt-3 mb-1 font-12">
              <strong><i class="mdi mdi-bookmark-outline"></i> 対応マーク:</strong>
            </p>
            <mark-label :mark="friendData.mark" :truncateNum="20" />
          </template>
          <mark-selection v-else editing @select="onSelectMark" :selected="friendData.mark ? friendData.mark.id : null" />

          <template v-if="!editing">
            <p class="mt-3 mb-1 font-12">
              <strong><i class="uil uil-clock"></i> 登録日時:</strong>
            </p>
            <p>{{ friendAddedAt }}</p>
          </template>
        </div>
      </div>
    </div>

    <loading-indicator :loading="loading"></loading-indicator>
    <!-- end card-body -->
  </div>
  <!-- end card-->
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Util from '@/core/util';

export default {
  props: {
    // TODO: using a state to store user profile, do not passing prop
    role: String
  },

  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      contentKey: 0,
      editing: false,
      loading: false,
      friendData: {}
    };
  },

  provide() {
    return { parentValidator: this.$validator };
  },

  watch: {
    friend: {
      handler(newFriend) {
        this.friendData = { ...newFriend };
      },
      immediate: true
    }
  },

  computed: {
    ...mapState('channel', {
      activeChannel: state => state.activeChannel,
      showUserDetail: state => state.showUserDetail
    }),
    friend() {
      return this.activeChannel.line_friend;
    },
    friendAddedAt() {
      return Util.formattedDate(this.friend.created_at);
    },
    detailPath() {
      return `${this.rootPath}/user/friends/${this.friend.id}`;
    },
    isAdmin() {
      return this.role === 'admin';
    }
  },

  methods: {
    ...mapMutations('channel', ['setShowUserDetail', 'setActiveChannel']),
    ...mapActions('channel', ['assign']),
    ...mapActions('friend', ['updateFriend', 'getFriend', 'setMark']),

    hideUserDetail() {
      if (this.showUserDetail) this.setShowUserDetail(false);
    },

    getAvatarImgObj(url) {
      const avatarImgObj = {
        src: url || '/img/no-image-profile.png',
        error: '/img/no-image-profile.png',
        loading: '/images/loading.gif'
      };
      return avatarImgObj;
    },

    onSelectMark(mark) {
      this.friendData.mark = mark;
    },

    onSelectTags(tags) {
      this.friendData.tags = tags;
    },

    onAssignAgent(agentId) {
      this.activeChannel.assignee_id = agentId;
    },

    async onSave() {
      const valid = await this.$validator.validateAll();
      if (!valid) return;
      this.loading = true;
      const formData = {
        id: this.friendData.id,
        display_name: this.friendData.display_name,
        note: this.friendData.note,
        tag_ids: this.friendData.tags ? this.friendData.tags.map(_ => _.id) : [],
        tester: this.friendData.tester,
        mark: this.friendData.mark
      };

      if (this.friendData.mark) {
        await this.setMark({
          friend_id: this.friendData.id,
          mark_id: this.friendData.mark.id
        });
      }

      await this.assign({
        channel_id: this.activeChannel.id,
        assignee_id: this.activeChannel.assignee_id || null
      });

      const response = await this.updateFriend(formData);
      if (response) {
        this.setActiveChannel({
          ...this.activeChannel,
          line_friend: this.friendData
        });
        window.toastr.success('友だち情報の更新が成功しました。');
      } else {
        window.toastr.error('友だち情報の更新が失敗しました。');
      }
      this.editing = false;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
.channel-friend-detail {
  .dropdown-tag {
    left: unset !important;
  }
}
</style>

<style lang="scss" scoped>
  .disabled {
    pointer-events: none;
  }

  .icon-fs {
    font-size: 1.5rem;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .chat-panel {
      height: calc(100vh - 50px);
    }
    .channel-info {
      padding-top: unset !important;
    }
  }

  @media (max-width: 767px) {
    .chat-panel {
      height: calc(100vh - 25px);
    }
  }

  @media (min-width: 767.1px) and (max-width: 999.9px) {
    .channel-info {
      padding-top: unset !important;
    }
  }

  @media (max-width: 1400px) and (min-width: 1000px) {
    .channel-info {
      height: 250px;
      overflow-y: scroll;
    }
  }

  @media (min-width: 1400.1px) {
    .channel-info {
      padding-top: unset !important;
    }
  }

</style>

<template>
<div class="dropdown">
  <button
    class="btn btn-light dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    フィルターを選択してください
  </button>
  <div
    class="dropdown-menu p-1"
    aria-labelledby="dropdownMenuButton"
    @click.stop
    style="width: 600px; height: 400px; overflow-y: auto;"
  >
    <div class="d-flex">
      <div class="folder-section me-3" style="width: 40%;">
        <table class="table table-hover m-0">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="text-nowrap">フォルダー</th>
            </tr>
          </thead>
        </table>
        <div class="tag-scroll folder-list overflow-auto border rounded" style="max-height: 300px;">
          <div
            v-for="(item, index) in folders"
            :key="index"
            :class="selectedFolder === index ? 'folder-item active bg-primary text-white' : 'folder-item bg-light'"
            @click="changeSelectedFolderFilter(index)"
            style="cursor: pointer; padding: 10px; border-bottom: 1px solid #dee2e6;"
          >
            <i :class="selectedFolder === index ? 'fas fa-folder-open me-2' : 'fas fa-folder me-2'"></i>
            <span class="tag-label fw-bold">{{ item.name }}</span>
            <small>({{ item.child_count }})</small>
          </div>
        </div>
      </div>
      <div class="filter-section flex-grow-1">
        <table class="table table-hover">
          <thead class="thead-light">
            <tr>
              <th>
                <span v-if="folders[selectedFolder]">{{ folders[selectedFolder].name }}</span>
              </th>
            </tr>
          </thead>
          <tbody v-if="filters && filters.length">
            <tr v-for="(item, index) in filters" :key="index" class="folder-item">
              <td class="d-flex w-100">
                <div class="box-item-name">
                  <p class="m-0 vw-10 item-name">{{ item.name }}</p>
                </div>
                <div
                  class="btn btn-info btn-sm ml-auto my-auto"
                  @click="selectFilter({ ...item, name: `${item.name }（コピー）`})"
                >
                  選択
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center pt40">データーがありません</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-3 text-center">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="queryParams.page"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="loadPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  props: ['id'],

  data() {
    return {
      selectedFolder: 0,
      isPc: true,
      queryParams: {}
    };
  },

  computed: {
    ...mapState('filter', {
      folders: state => state.folders,
      filters: state => state.filters,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolder];
    }
  },

  async beforeMount() {
    await this.getFolders({ is_filter_draft: true });
    this.changeSelectedFolderFilter(0);
    this.loading = false;
  },

  methods: {
    ...mapMutations('filter', ['setQueryParams']),
    ...mapActions('filter', ['getFilters', 'getFolders']),

    selectFilter(filter) {
      const data = _.cloneDeep(filter);
      this.$emit('selectFilter', data);

      const dropdown = document.getElementById('dropdownMenuButton');
      if (dropdown) {
        dropdown.click();
      }
    },

    changeSelectedFolderFilter(index) {
      this.selectedFolder = index;
      this.isPc = true;
      this.queryParams.page = 1;
      this.queryParams.per = 5;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    async loadPage() {
      this.$nextTick(async() => {
        this.loading = true;
        this.setQueryParams(this.queryParams);
        await this.getFilters();
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.folder-section {
  min-width: 200px;
}

.filter-section {
  flex-grow: 1;
}

.dropdown-menu {
  max-width: 600px;
  max-height: 400px;
  overflow-y: auto;
}

.folder-item {
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
}

.folder-item:hover {
  background-color: #f8f9fa;
}

.folder-item.active {
  background-color: #007bff;
  color: white;
}
</style>

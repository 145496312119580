<template>
  <div>
    <div class="card">
      <div class="card-body">
        <date-picker
          class="date-picker-input"
          placeholder="期間"
          v-model="dateRange"
          confirm
          confirm-text="表示"
          range
        />
        <bar-line-chart
          v-if="!loading"
          class="w-100"
          :data="lineChartData"
          :options="lineChartOptions"
        />
      </div>
    </div>

    <home-friend-statistics />
  </div>
</template>

<script lang="ts">
import _ from 'lodash';
import moment from 'moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import { mapMutations, mapActions, mapState } from 'vuex';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ja';

import {
  Chart as ChartJS,
  registerables,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
} from 'chart.js';

ChartJS.register(
  ...registerables,
  zoomPlugin,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export default {
  data() {
    return {
      dateRange: [],
      lineChartData: {
        labels: [],
        datasets: [
          {
            type: 'line',
            label: '実数',
            data: [],
            borderColor: '#00BFFF',
            borderWidth: 2,
            tension: 0.3,
            fill: false,
            yAxisID: 'y',
            pointBackgroundColor: '#00BFFF'
          },
          {
            type: 'bar',
            label: '増減',
            data: [],
            yAxisID: 'y1',
            backgroundColor: '#C41E3A'
          }
        ]
      },
      lineChartOptions: {
        responsive: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true
              },
              pinch: {
                enabled: true
              },
              animation: {
                duration: 1000,
                easing: 'easeOutCubic'
              },
              // limits: {
              //   x: { min: 0, max: 100 },
              //   y: { min: 0, max: 100 }
              // },
              mode: 'x'
            }
          },
          legend: {
            display: true,
            position: 'top',
            align: 'start'
          },
          tooltip: {
            mode: 'index'
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: '日付'
            }
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: '有効友だち数'
            }
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false
            },
            title: {
              display: true,
              text: '前日比'
            }
          }
        }
      }
    };
  },

  watch: {
    statistics(val) { this.mappingDataToLineChart(val); },
    dateRange(date) { this.handleDateRangeChange(date); }
  },

  beforeMount() {
    this.queryAnMonthRecently();
  },

  computed: {
    ...mapState('friendStatistic', {
      loading: state => state.loading,
      statistics: state => state.friendStatistics
    })
  },

  methods: {
    ...mapMutations('friendStatistic', ['setQueryParams']),
    ...mapActions('friendStatistic', ['fetchFriendStatistics']),

    queryAnMonthRecently() {
      const dateTo = moment();
      const dateFrom = moment().subtract(1, 'months');

      this.setQueryParams({
        date_gteq: dateFrom.format('YYYY-MM-DD'),
        date_lteq: dateTo.format('YYYY-MM-DD'),
        per: 1 + dateTo.diff(dateFrom, 'days')
      });
    },

    handleDateRangeChange(date) {
      // on clear data
      if (!date[0] && !date[1]) {
        this.queryAnMonthRecently();
        this.fetchFriendStatistics();
        return;
      }

      const dateFrom = moment(date[0]);
      const dateTo = moment(date[1]);

      this.setQueryParams({
        date_gteq: dateFrom.format('YYYY-MM-DD'),
        date_lteq: dateTo.format('YYYY-MM-DD'),
        per: 1 + dateTo.diff(dateFrom, 'days')
      });

      this.fetchFriendStatistics();
    },

    mappingDataToLineChart(val) {
      // convert data
      const arr = [...val].reverse();
      const labels = arr.map(i => moment(i.date).format('YYYY年MM月DD日'));
      const dataY0 = arr.map(i => i.total_friends_count);
      const dataY1 = arr.map(i => Math.max(i.added_friends_count - i.blocked_friends_count, 0));

      // set data y0
      this.lineChartData.labels = labels;
      this.lineChartData.datasets[0].data = dataY0;
      this.lineChartOptions.scales.y.max = _.max(dataY0);
      this.lineChartOptions.scales.y.min = _.min(dataY0);

      // set data y1
      this.lineChartData.datasets[1].data = dataY1;
      this.lineChartOptions.scales.y1.max = _.max(dataY1);
      this.lineChartOptions.scales.y1.min = _.min(dataY1);
    }
  }
};
</script>

<style lang="scss" scoped>
.date-picker-input {
  width: 250px;
  float: right;
}
</style>

<template>
  <div>
    <div
      class="d-flex align-items-center w-100"
      :class="getClassName"
      v-if="!isEdit || !active"
      @click="changeSelected"
    >
      <div class="d-flex w-100 align-items-center justify-content-between">
        <div class="mxw-100 text-truncate">
          <i class="mdi mdi-drag text-secondary mr-2"></i>
          <i :class="active ? 'fas fa-folder-open' : 'fas fa-folder'"></i>
          <span style="vertical-align: middle; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{
            data.name
          }}</span
          ><span>({{ childsCount }})</span>
        </div>

        <div class="dropdown" v-if="active && data.name != '未分類' && !isPerview">
          <div class="btn-group">
            <div
              class="btn btn-light btn-sm dropdown-toggle dropdown-icon"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              編集
            </div>
            <div class="dropdown-menu bg-white" role="menu">
              <a role="button" class="dropdown-item" @click.stop="changeName">名前を変える</a>
              <div class="dropdown-divider" v-if="canDelete(data)"></div>
              <a
                role="button"
                class="dropdown-item"
                data-toggle="modal"
                data-target="#modalDeleteFolder"
                @click="deleteFolder"
                v-if="canDelete(data)"
                >フォルダーを削除</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column w-100" :class="getClassName" v-if="isEdit && active">
      <div class="d-flex align-items-center input-group">
        <input
          type="text"
          placeholder="フォルダー名"
          class="form-control"
          v-model.trim="folderName"
          @keyup.enter="enterSubmitChangeName"
          @compositionend="compositionend($event)"
          @compositionstart="compositionstart($event)"
          @click.stop
          maxlength="33"
          name="folder_name"
          data-vv-as="フォルダー名"
          v-validate="'required|max:32'"
        />
        <div class="btn btn-light btn-sm ml-auto" @click="submitChangeName" ref="buttonChange">決定</div>
      </div>
      <error-message :message="errors.first('folder_name')" v-if="errors.first('folder_name')"></error-message>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'active', 'index', 'type', 'isPerview'],
  data() {
    return {
      isEdit: false,
      isEnter: true,
      folderName: ''
    };
  },

  created() {
    this.folderName = this.data.name;
  },

  computed: {
    // Tính toán số lượng con của folder dựa trên `type`
    childsCount() {
      if (this.data.child_count !== undefined) {
        return this.data.child_count;
      }

      switch (this.type) {
      case 'tag':
        return this.data.tags.length;
      case 'scenario':
        return this.data.scenarios.length;
      case 'template_message':
        return this.data.templates.length;
      case 'rich_menu':
        return this.data.rich_menus.length;
      case 'auto_response':
        return this.data.auto_responses.length;
      case 'survey':
        return this.data.surveys.length;
      case 'variable':
        return this.data.variables.length;
      case 'reminder':
        return this.data.reminders.length;
      case 'filter':
        return this.data.filters ? this.data.filters.length : 0;
      case 'site':
        return this.data.sites ? this.data.sites.length : 0;
      case 'stream_route':
        return this.data.stream_routes ? this.data.stream_routes.length : 0;
      }
      return 0;
    },

    getClassName() {
      return 'folder-item ' + (this.active ? 'active' : '');
    }
  },
  methods: {
    canDelete() {
      if (this.data.child_count !== undefined) {
        return !(
          (this.type === 'survey' && (this.data.child_count > 0 || this.data?.child_exist > 0)) ||
          (this.type === 'reminder' && this.data.child_count > 0)
        );
      }

      return !(
        (this.type === 'survey' && this.data.surveys.length > 0) ||
        (this.type === 'variable' && this.data.variables.length > 0) ||
        (this.type === 'reminder' && this.data.reminders.length > 0)
      );
    },

    changeName() {
      this.isEdit = true;
    },

    changeSelected() {
      this.isEdit = false;
      this.folderName = this.data.name;

      this.$emit('changeSelected', { index: this.index, folderId: this.data.id });
    },

    submitChangeName() {
      this.$validator.validateAll().then(passed => {
        if (!passed) {
          return;
        }
        this.isEdit = false;
        if (this.folderName !== this.data.name) {
          this.$emit('editTag', { id: this.data.id, name: this.folderName });
        }
      });
    },

    enterSubmitChangeName(e) {
      if (!this.isEnter) {
        this.isEnter = true;
        return;
      }
      this.$refs.buttonChange.click();
    },

    deleteFolder() {
      this.$emit('deleteTag');
    },

    compositionend() {
      this.isEnter = false;
    },

    compositionstart() {
      this.isEnter = true;
    },
  }
};
</script>

<style scoped>
.folder-item {
  display: flex;
  cursor: pointer;
  padding: 10px;
  min-height: 55px !important;
}
.active {
  background-color: #e0e0e0;
}
.actions {
  display: flex;
  gap: 5px;
}
.action-button {
  font-size: 20px;
  border: 1px solid;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.action-button i {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.fa-folder {
  color: #fedc67;
}
.fa-folder-open {
  color: #f0ad4e;
}
.fa-folder::before,
.fa-folder-open::before {
  margin-right: 5px;
  vertical-align: sub;
}
</style>

<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <reorder-folder-left
          type="auto_response"
          :data="folders"
          :isPc="isPc"
          :selectedFolder="selectedFolderIndex"
          @changeSelectedFolder="onSelectedFolderChanged"
          @submitUpdateFolder="submitUpdateFolder"
          @submitCreateFolder="submitCreateFolder"
          @reorder="onDragFolderEnd"
        />
        <div class="flex-grow-1 folder-right" :key="contentKey">
          <div class="col-r d-flex">
            <a
              v-if="folders && folders.length && folders[selectedFolderIndex]"
              :href="rootPath + '/user/auto_responses/new?folder_id=' + folders[selectedFolderIndex].id"
              class="btn btn-primary"
            >
              <i class="uil-plus"></i> 新規作成
            </a>

            <div class="ml-auto d-flex">
              <div class="input-group app-search">
                <input
                    type="text"
                    class="form-control dropdown-toggle fw-250"
                    placeholder="検索..."
                    v-model="queryParams.name_cont"
                />
                <span class="mdi mdi-magnify search-icon"></span>
                <div class="input-group-append">
                  <div class="btn btn-primary" @click="search()">検索</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="mt-2">
              <div class="overflow-auto table-content">
                <table class="table mb-0 table-centered">
                  <thead class="thead-light">
                    <tr>
                      <th></th>
                      <th class="mw-120">自動応答名</th>
                      <th class="mw-90 mxw-105">キーワード</th>
                      <th class="mw-105">メッセージ</th>
                      <th class="mw-105">状況</th>
                      <th class="mw-105">ヒット数</th>
                      <th class="mw-105">操作</th>
                      <th class="mw-150">フォルダー</th>
                      <th class="mw-130"></th>
                    </tr>
                  </thead>
                  <draggable
                    tag="tbody"
                    v-model="autoResponsesCopy"
                    @end="onDragAutoResponseEnd"
                    drag-class="dragging-item"
                    ghost-class="destination-item"
                  >
                    <tr
                      v-for="(autoResponse, index) in autoResponsesCopy"
                      :key="autoResponse.id"
                      class="draggable-row">
                      <td class="drag-icon-cell">
                        <i class="mdi mdi-drag text-secondary"></i>
                      </td>
                      <td>
                        <div class="mxw-200 text-truncate max-2-lines white-space-normal">{{ autoResponse.name }}</div>
                      </td>
                      <td>
                        <div><small>どれか1つにマッチ</small></div>
                        <div>
                          <span
                            class="badge badge-pill badge-warning mr-1 pt-1"
                            v-for="(keyword, index) in autoResponse.keywords"
                            v-bind:key="index"
                          >
                            <div class="mxw-130 text-truncate">{{ keyword }}</div>
                          </span>
                        </div>
                      </td>
                      <td class="mw-200">
                        <div class="overflow-auto" style="max-height: 150px;">
                          <div v-for="(item, index) in autoResponse.messages" v-bind:key="index" class="text-left">
                            <message-content :data="item"></message-content>
                          </div>
                        </div>
                      </td>

                      <td>
                        <template v-if="autoResponse.status === 'enabled'">
                          <i class="mdi mdi-circle text-success"></i> 有効
                        </template>
                        <template v-else> <i class="mdi mdi-circle"></i> 無効 </template>
                      </td>

                      <td>
                        {{ autoResponse.hit_count }}
                      </td>

                      <td>
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-light btn-sm dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            操作 <span class="caret"></span>
                          </button>
                          <div class="dropdown-menu">
                            <a role="button" class="dropdown-item" @click="openEdit(autoResponse)">自動応答を編集</a>
                            <a role="button" class="dropdown-item" @click="updateAutoResponseStatus(autoResponse)"
                              >{{ autoResponse.status === "enabled" ? "OFF" : "ON" }}にする</a
                            >
                            <a
                              role="button"
                              class="dropdown-item"
                              data-toggle="modal"
                              data-target="#modalCopyAutoResponse"
                              @click="showConfirmCopyModal(autoResponse)"
                              >自動応答をコピー</a
                            >
                            <a
                              role="button"
                              class="dropdown-item"
                              data-toggle="modal"
                              data-target="#modalDeleteAutoResponse"
                              @click="showConfirmDeleteModal(autoResponse)"
                              >自動応答を削除</a
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="mw-90 mxw-120 text-truncate" v-if="curFolder">{{ curFolder.name }}</div>
                        <span class="font-12">{{ formattedDate(autoResponse.created_at) }}</span>
                      </td>
                      <td>
                        <div class="actions ml-auto mr-2">
                          <button
                          @click.stop="moveAutoResponse(index, 'up')"
                          :class="{ disabled: index === 0 && queryParams.page === 1 }"
                          class="btn btn-light btn-sm action-button"
                          :disabled="index === 0 && queryParams.page === 1"
                        >
                          <i class="dripicons-chevron-up"></i>
                        </button>
                        <button
                          @click.stop="moveAutoResponse(index, 'down')"
                          :class="{ disabled: index === autoResponsesCopy.length - 1 && queryParams.page === totalPages }"
                          class="btn btn-light btn-sm action-button"
                          :disabled="index === autoResponsesCopy.length - 1 && queryParams.page === totalPages"
                        >
                          <i class="dripicons-chevron-down"></i>
                        </button>
                        </div>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-3 text-center">
              <b-pagination
                  v-if="totalRows > perPage"
                  v-model="queryParams.page"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @change="loadPage"
                  aria-controls="my-table"
              ></b-pagination>
            </div>
            <div class="text-center mt-5" v-if="!loading && totalRows === 0">
              <b>自動応答はありません。</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-indicator :loading="loading"></loading-indicator>

    <!-- START: Delete folder modal -->
    <modal-confirm
      id="modalDeleteFolder"
      title="このフォルダーを削除してもよろしいですか？"
      type="delete"
      @confirm="submitDeleteFolder"
    >
      <template v-slot:content v-if="folders[selectedFolderIndex]">
        <span>フォルダ名：{{ folders[selectedFolderIndex].name }}</span>
      </template>
    </modal-confirm>
    <!-- END: Delete folder modal -->

    <!-- START: Delete auto response modal -->
    <modal-confirm
      id="modalDeleteAutoResponse"
      title="自動応答を削除してもよろしいですか？"
      type="delete"
      @confirm="submitDeleteAutoResponse(autoResponse)"
    >
      <template v-slot:content v-if="autoResponse">
        <div>
          <span>自動応答: {{ autoResponse.name }}</span>
        </div>
      </template>
    </modal-confirm>
    <!-- END: Delete auto response modal -->

    <!-- START: Copy auto response modal -->
    <modal-confirm
      id="modalCopyAutoResponse"
      type="confirm"
      title="自動応答をコピーしてもよろしいですか？"
      @confirm="submitCopyAutoResponse()"
    >
      <template v-slot:content v-if="autoResponse">
        <div>自動応答: {{ autoResponse.name }}</div>
      </template>
    </modal-confirm>
    <!-- END: Copy auto response modal -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Util from '@/core/util';

export default {
  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      isPc: true,
      selectedFolderIndex: 0,
      loading: true,
      contentKey: 0,
      queryParams: {
        name_cont: null
      },
      autoResponsesCopy: []
    };
  },

  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },

  async beforeMount() {
    await this.getFolders();
    const folderId = Number.parseInt(Util.getParamFromUrl('folder_id'));
    const index = folderId > 0 ? _.findIndex(this.folders, _ => _.id === folderId) : 0;
    this.onSelectedFolderChanged(index > 0 ? index : 0);
    this.loading = false;
  },

  computed: {
    ...mapGetters('autoResponse', ['getQueryParams']),
    ...mapState('autoResponse', {
      folders: state => state.folders,
      autoResponses: state => state.autoResponses,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolderIndex];
    },

    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    }
  },

  methods: {
    ...mapMutations('autoResponse', ['setQueryParams', 'updateFolderOrder', 'updateAutoResponseOrder']),
    ...mapActions('autoResponse', [
      'getAutoResponses',
      'deleteAutoResponse',
      'updateAutoResponse',
      'copyAutoResponse',
      'createFolder',
      'deleteFolder',
      'updateFolder',
      'getFolders',
      'reorderFolders',
      'reorderAutoResponses'
    ]),

    async onDragFolderEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.folders,
        this.reorderFolders,
        this.updateFolderOrder
      );
    },

    async moveAutoResponse(index, direction) {
      this.queryParams.page = await Util.moveItem(
        this.autoResponsesCopy,
        index,
        direction,
        this.reorderAutoResponses.bind(this),
        this.updateAutoResponseOrder.bind(this),
        null,
        this.queryParams.page,
        this.totalPages
      );
      await this.loadPage();
    },

    async onDragAutoResponseEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.autoResponsesCopy,
        this.reorderAutoResponses.bind(this),
        this.updateAutoResponseOrder.bind(this)
      );

      await this.getAutoResponses();
    },

    showConfirmDeleteModal(autoResponse) {
      this.autoResponse = autoResponse;
    },

    showConfirmCopyModal(autoResponse) {
      this.autoResponse = autoResponse;
    },

    tags(strtag) {
      return typeof strtag === 'string' ? (strtag.length > 0 ? strtag.split(',') : []) : strtag;
    },

    async updateAutoResponseStatus(autoResponse) {
      const payload = { id: autoResponse.id, status: autoResponse.status === 'enabled' ? 'disabled' : 'enabled' };
      const response = await this.updateAutoResponse(payload);
      const url = `${this.rootPath}/user/auto_responses?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('自動応答状況の変更は完了しました。', url);
      } else {
        window.toastr.error('自動応答状況の変更は失敗しました。');
      }
    },

    async submitDeleteAutoResponse() {
      if (!this.autoResponse) return;
      const response = await this.deleteAutoResponse(this.autoResponse.id);
      const url = `${this.rootPath}/user/auto_responses?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('自動応答の削除は完了しました。', url);
      } else {
        window.toastr.error('自動応答の削除は失敗しました。');
      }
    },

    onSelectedFolderChanged(index) {
      this.selectedFolderIndex = index;
      this.isPc = true;
      this.autoResponses = this.folders[index].auto_responses;
      this.queryParams.page = 1;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    async submitUpdateFolder(folder) {
      const response = await this.updateFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの変更は完了しました。');
      } else {
        window.toastr.error('フォルダーの変更は失敗しました。');
      }
    },

    async submitCreateFolder(value) {
      const response = await this.createFolder(value);
      if (response) {
        window.toastr.success('フォルダーの作成は完了しました。');
      } else {
        window.toastr.error('フォルダーの作成は失敗しました。');
      }
    },

    async submitDeleteFolder() {
      const response = await this.deleteFolder(this.folders[this.selectedFolderIndex].id);
      if (response) {
        window.toastr.success('フォルダーの削除は完了しました。');
        this.onSelectedFolderChanged(0);
      } else {
        window.toastr.error('フォルダーの削除は失敗しました。');
      }
    },

    async submitCopyAutoResponse() {
      const response = await this.copyAutoResponse(this.autoResponse.id);
      const url = `${this.rootPath}/user/auto_responses?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('自動応答のコピーは完了しました。', url);
      } else {
        window.toastr.error('自動応答のコピーは失敗しました。');
      }
    },

    backToFolder() {
      this.isPc = false;
    },

    openEdit(autoResponse) {
      window.open(`${process.env.MIX_ROOT_PATH}/user/auto_responses/${autoResponse.id}/edit`);
    },

    formattedDate(date) {
      return Util.formattedDate(date);
    },

    search() {
      this.queryParams.page = 1;
      this.loadPage(true);
    },

    forceRerender() {
      this.contentKey++;
    },

    async loadPage(isLoadFolder = false) {
      this.$nextTick(async() => {
        this.setQueryParams(this.queryParams);
        this.loading = true;
        if (isLoadFolder) {
          await this.getFolders();
        }
        await this.getAutoResponses();
        this.autoResponsesCopy = this.autoResponses;
        this.forceRerender();
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss"  scoped>
  ::v-deep {
    td .emojione {
      width: 20px !important;
    }

    td .chat-item > .sticker-static {
      width: 50px !important;
    }

    td .chat-item {
      padding: 0px;
    }

    .chat-item-text {
      text-align: left;
    }

    .dragging-item {
      opacity: 0.8;
      border: 2px solid #008a00 !important;
    }

    .destination-item {
      opacity: 0.5;
      border: 2px dashed #6a0dad;
      background-color: #d8bfd8;
    }
  }
</style>

import friendStatisticApi from '../api/friend_statistic_api';

export const state = {
  loading: true,
  friendStatistics: [],
  totalRows: 0,
  perPage: 0,
  curPage: 1,
  queryParams: {
    per: 4,
    page: 1,
    date_gteq: '',
    date_lteq: ''
  }
};

export const mutations = {
  setQueryParams(state, params) {
    Object.assign(state.queryParams, params);
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  setFriendStatistics(state, data) {
    state.friendStatistics = data;
  },

  setLoading(state, status) {
    state.loading = status;
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  fetchFriendStatistics(context) {
    const params = {
      per: state.queryParams.per,
      page: state.queryParams.page,
      q: _.omit(state.queryParams, 'per', 'page')
    };

    context.commit('setLoading', true);
    return friendStatisticApi.friendStatistics(params).done((res) => {
      context.commit('setFriendStatistics', res.data);
      context.commit('setMeta', res.meta);
      context.commit('setLoading', false);
      return Promise.resolve(res);
    }).fail((err) => {
      return Promise.reject(err);
    });
  }
};

<template>
    <li class="p-2 mb-2 border rounded bg-light">
      <template v-if="condition.key_name === 'infor_line_friend'">
        <template v-if="condition.type === 'text'">
          <label>{{ condition.text }}:</label>
          <span>{{ condition.value }}</span>
          <small class="text-muted">(Operator: {{ mapOperatorName(condition.operator) }})</small>
        </template>
        <template v-else-if="condition.type === 'date'">
          <div class="condition-item">
            <label class="condition-label">{{ condition.text }}:</label>
            <span class="condition-value">{{ condition.items[0].text || 'N/A' }}</span>
            <label class="condition-label">検索条件:</label>
            <span class="condition-value">
              {{ condition.operator === 'not_between'
                ? `開始: ${condition.value || 'N/A'}, 終了: ${condition.second_value || 'N/A'}`
                : condition.value || 'N/A' }}
            </span>
            <small class="text-muted">(Operator: {{ mapOperatorName(condition.operator) }})</small>
          </div>
        </template>
        <template v-else-if="condition.type === 'time'">
          <label>{{ condition.text }}:</label>
          <span>{{ condition.value }}</span>
          <small class="text-muted">(Operator: {{ mapOperatorName(condition.operator) }})</small>
        </template>
        <template v-else>
          <label>{{ condition.text }}:</label>
          <span>不明な条件</span>
        </template>
      </template>
      <template v-else>
        <template v-if="condition.folder">
          <label>で検索:</label>
          <span>{{ condition.folder === 'tag' ? 'タグ' : 'フォルダー' }}</span>
        </template>
        <label>{{ condition.text }}:</label>
        <span>{{ renderConditionValue(condition) }}</span>
        <small class="text-muted">(Operator: {{ mapOperatorName(condition.operator) }})</small>
      </template>
    </li>
  </template>

<script>
export default {
  props: {
    condition: {
      type: Object,
      required: true
    },
    mapOperatorName: {
      type: Function,
      required: true
    },
    renderConditionValue: {
      type: Function,
      required: true
    }
  }
};
</script>

<style scoped>
.condition-item {
  margin-bottom: 16px;
}

.condition-label {
  font-weight: bold;
  margin-bottom: 4px;
  display: block;
}

.condition-value {
  margin-bottom: 8px;
  color: #555;
}
</style>

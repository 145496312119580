import FilterApi from '../api/filter_api';
import FolderAPI from '../api/folder_api';
import { convertKeysToSnakeCase } from '../../core/convertKeys';

export const state = {
  folders: [],
  filters: [],
  friends: [],
  totalRows: 0,
  perPage: 0,
  curPage: 1,
  queryParams: {
    page: 1,
    per: 10,
    folder_id_eq: ''
  },
  friendMeta: {
    totalRows: 0,
    perPage: 0,
    curPage: 1
  }
};

export const mutations = {
  setFilters(state, filters) {
    state.filters = filters;
  },

  setFriends(state, friends) {
    state.friends = friends;
  },

  setCurPage(state, curPage) {
    state.curPage = curPage;
  },

  setQueryParams(state, params) {
    Object.assign(state.queryParams, params);
  },

  setQueryParam(state, param) {
    Object.assign(state.queryParams, param);
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  setFriendMeta(state, meta) {
    state.friendMeta.totalRows = meta.total_count;
    state.friendMeta.perPage = meta.limit_value;
    state.friendMeta.curPage = meta.current_page;
  },

  pushFolder(state, folder) {
    folder.scenarios = [];
    state.folders.push(folder);
  },

  setFolders(state, folders) {
    state.folders = folders;
  },

  updateFolder(state, newItem) {
    const item = state.folders.find(item => item.id === newItem.id);
    if (item) {
      item.name = newItem.name;
    }
  },

  deleteFolder(state, id) {
    const index = state.folders.findIndex(_ => _.id === id);
    state.folders.splice(index, 1);
  },

  updateFolderOrder(state, { oldIndex, newIndex }) {
    const movedFolder = state.folders.splice(oldIndex, 1)[0];
    state.folders.splice(newIndex, 0, movedFolder);
  },

  updateFilterOrder(state, { oldIndex, newIndex }) {
    const movedFilter = state.filters.splice(oldIndex, 1)[0];
    state.filters.splice(newIndex, 0, movedFilter);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  async getFolders(context) {
    try {
      const params = {
        type: 'filter'
      };
      const response = await FolderAPI.list(params);
      context.commit('setFolders', response);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async createFolder(context, payload) {
    try {
      const folder = await FolderAPI.create(payload);
      context.commit('pushFolder', folder);
      return folder;
    } catch (error) {
      return null;
    }
  },

  async updateFolder(context, payload) {
    try {
      const response = await FolderAPI.update(payload);
      context.commit('updateFolder', response);
      return response;
    } catch (error) {
      return null;
    }
  },

  async deleteFolder(context, id) {
    try {
      const response = await FolderAPI.delete(id);
      context.commit('deleteFolder', id);
      return response;
    } catch (error) {
      return null;
    }
  },

  async getFilters(context) {
    try {
      const params = {
        page: state.queryParams.page,
        per: state.queryParams.per,
        q: _.omit(state.queryParams, 'page')
      };
      const response = await FilterApi.list(params);
      context.commit('setFilters', response.data);
      context.commit('setMeta', response.meta);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async getFilter(_, id) {
    try {
      return await FilterApi.get(id);
    } catch (error) {
      return null;
    }
  },

  async createFilter(context, query) {
    try {
      const convertedPayload = convertKeysToSnakeCase(query);
      return await FilterApi.create(convertedPayload);
    } catch (error) {
      return null;
    }
  },

  async updateFilter(context, query) {
    try {
      return await FilterApi.update(query);
    } catch (error) {
      return null;
    }
  },

  async deleteFilter(context, id) {
    try {
      return await FilterApi.delete(id);
    } catch (error) {
      return null;
    }
  },

  async reorderFolders(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await FolderAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to swap folder positions:', error);
    }
  },

  async reorderFilters(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await FilterApi.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to reorder filters:', error);
    }
  }
};

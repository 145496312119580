<template>
  <div class="mart-setting d-flex align-items-center mt-3 mb-1">
    <div class="w-150">
      <strong>対応マーク</strong>
    </div>
    <div class="w-150">
      <button
        v-if="markSelected"
        class="btn btn-light btn-xs box-shadow-none"
        :style="{
          backgroundColor: markSelected.color,
          color: getInversionColor(markSelected.color),
          inlineSize: 'max-content'
        }">
        {{markSelected.name}}
      </button>
    </div>
    <div v-show="editing" class="ml-auto btn-group">
      <button
        type="button"
        class="btn btn-light btn-sm dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        变更 <span class="caret"></span>
      </button>
      <div v-show="marks.length > 0" class="dropdown-menu">
        <a
          v-for="(mark, idx) in marks"
          :key="idx"
          role="button"
          class="dropdown-item d-flex px-2 align-items-center"
          @click="onSelectMark(mark)"
        >
        <div
        :style="{
          width: '15px',
          height: '15px',
          marginRight: '5px',
          backgroundColor: mark.color,
          color: getInversionColor(mark.color),
        }"
        ></div>
        {{ mark.name | truncate(10) }}
      </a>
      <a href="/user/marks?showBtnBack=1" class="dropdown-item d-flex px-2 align-items-center border-top mt-1">
        <i class="mdi mdi-link-variant-plus mr-1"></i>
        対応マーク設定
      </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Util from '@/core/util';

export default {
  props: {
    selected: {
      type: Number,
      required: false
    },
    editing: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      marks: [],
      markSelected: null
    };
  },

  async beforeMount() {
    this.marks = await this.getMarks();
    if (this.selected) this.findMark(this.selected);
  },

  methods: {
    ...mapActions('mark', ['getMarks']),

    onSelectMark(mark) {
      this.markSelected = mark;
      this.$emit('select', mark);
    },

    findMark(markId) {
      this.markSelected = this.marks.find(_ => _.id === markId);
    },

    getInversionColor(color) {
      return Util.getInversionColor(color);
    }
  }
};
</script>
<style lang="scss" scoped>
</style>

export default {
  list: (query) => {
    return window.$.ajax({
      url: `${process.env.MIX_ROOT_PATH}/user/data_transfers`,
      method: 'GET',
      dataType: 'json',
      data: query,
      contentType: 'application/json',
      cache: false
    });
  },

  get: (code) => {
    return window.$.ajax({
      url: process.env.MIX_ROOT_PATH + '/user/data_transfers/' + code,
      method: 'GET',
      dataType: 'json',
      contentType: 'application/json'
    });
  },

  create: (payload) => {
    return window.$.ajax({
      url: `${process.env.MIX_ROOT_PATH}/user/data_transfers`,
      method: 'POST',
      data: JSON.stringify(payload),
      contentType: 'application/json'
    });
  },

  transfer: (code) => {
    return window.$.ajax({
      url: `${process.env.MIX_ROOT_PATH}/user/data_transfers/${code}/transfer`,
      method: 'POST',
      contentType: 'application/json'
    });
  }
};

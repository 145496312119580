<template>
  <div>
    <label class="font-weight-bold mb-1">条件 <required-mark /></label>
    <b-form-select
      v-model="operator"
      :options="computedOptions"
      class="w-50"
      v-validate="'required'"
      :name="name"
      :disabled="isDisabled"
    ></b-form-select>
    <span v-if="errorMessage" class="text-danger">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    operator: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedOptions() {
      if (this.options.length === 0) {
        return [{ value: '', text: 'No options available' }];
      }
      return [...this.options];
    }
  },
  watch: {
    operator(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('update:operator', newVal);
      }
    }
  }
};
</script>

<style scoped>
.w-50 {
  width: 50%;
}
.text-danger {
  color: #dc3545;
}
</style>

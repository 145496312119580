export default {
  friendStatistics: (query) => {
    return window.$.ajax({
      url: `${process.env.MIX_ROOT_PATH}/user/friend_statistics`,
      method: 'GET',
      data: query,
      dataType: 'json',
      contentType: 'application/json'
    });
  }
};

<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <folder-left
          type="reminder"
          :data="folders"
          :isPc="isPc"
          :selectedFolder="selectedFolderIndex"
          @changeSelectedFolder="onSelectedFolderChanged"
          @submitUpdateFolder="submitUpdateFolder"
          @submitCreateFolder="submitCreateFolder"
        />
        <div class="flex-grow-1 folder-right" :key="contentKey">
          <a
            v-if="folders && folders.length && curFolder"
            :href="`${rootPath}/user/reminders/new?folder_id=${curFolder.id}`"
            class="btn btn-primary"
          >
            <i class="uil-plus"></i> 新規作成
          </a>
          <div class="mt-2" v-if="curFolder">
            <div class="overflow-auto table-content">
              <table class="table table-centered mb-0">
                <thead class="thead-light">
                  <tr>
                    <th class="mw-120">リマインダ名</th>
                    <th class="mw-260">操作</th>
                    <th class="mw-150">フォルダー</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(reminder, index) in reminders" v-bind:key="reminder.id">
                    <td>
                      <p class="mxw-250 text-truncate max-2-lines white-space-normal">{{ reminder.name }}</p>
                    </td>
                    <td>
                      <div class="d-flex">
                        <a
                          role="button"
                          :href="`${rootPath}/user/reminders/${reminder.id}/episodes`"
                          class="btn btn-sm btn-light mr-1 text-nowrap"
                          >配信タイミング一覧（{{ reminder.episodes_count || 0 }}）</a
                        >
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-light btn-sm dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            操作 <span class="caret"></span>
                          </button>
                          <div class="dropdown-menu">
                            <a
                              role="button"
                              class="dropdown-item"
                              :href="`${rootPath}/user/reminders/${reminder.id}/edit?folder_id=${curFolder.id}`"
                              >リマインダを編集する</a
                            >
                            <a
                              role="button"
                              class="dropdown-item"
                              data-toggle="modal"
                              data-target="#modalCopyReminder"
                              @click="curReminderIndex = index"
                              >リマインダをコピー</a
                            >
                            <a
                              role="button"
                              class="dropdown-item"
                              data-toggle="modal"
                              data-target="#modalDeleteTemplate"
                              @click="curReminderIndex = index"
                              v-if="reminder.destroyable"
                              >リマインダを削除</a
                            >
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>{{ curFolder.name }}</div>
                      <div class="text-sm">{{ formattedDate(reminder.created_at) }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-center mt-3 text-center">
              <b-pagination
                  v-if="totalRows > perPage"
                  v-model="queryParams.page"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @change="loadPage"
                  aria-controls="my-table"
              ></b-pagination>
            </div>
            <div class="text-center mt-5" v-if="!loading && totalRows === 0">
              <b>リマインダはありません。</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-indicator :loading="loading"></loading-indicator>

    <!-- START: Delete folder modal -->
    <modal-confirm
      title="このフォルダーを削除してもよろしいですか？"
      id="modalDeleteFolder"
      type="delete"
      @confirm="submitDeleteFolder"
    >
      <template v-slot:content v-if="curFolder">
        <span>フォルダー名：{{ curFolder.name }}</span>
      </template>
    </modal-confirm>
    <!-- END: Delete folder modal -->

    <!-- START: Delete reminder modal -->
    <modal-confirm
      title="このリマインダを削除してもよろしいですか？"
      id="modalDeleteTemplate"
      type="delete"
      @confirm="submitDeleteReminder"
    >
      <template v-slot:content>
        <div v-if="curReminder">
          リマインダ名：<b>{{ curReminder.name }}</b>
        </div>
      </template>
    </modal-confirm>
    <!-- END: Delete template modal -->

    <!-- START: Copy template modal -->
    <modal-confirm
      title="このリマインダをコピーしてもよろしいですか？"
      id="modalCopyReminder"
      type="confirm"
      @confirm="submitCopyReminder"
    >
      <template v-slot:content>
        <div v-if="curReminder">
          リマインダ名：<b>{{ curReminder.name }}</b>
        </div>
      </template>
    </modal-confirm>
    <!-- END: Copy template modal -->
  </div>
</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import Util from '@/core/util';

export default {
  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      isPc: true,
      selectedFolderIndex: 0,
      curReminderIndex: null,
      loading: true,
      contentKey: 0,
      queryParams: {}
    };
  },

  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },

  async beforeMount() {
    await this.getFolders();
    const folderId = Number.parseInt(Util.getParamFromUrl('folder_id'));
    const index = folderId > 0 ? _.findIndex(this.folders, _ => _.id === folderId) : 0;
    this.onSelectedFolderChanged(index > 0 ? index : 0);
    this.loading = false;
  },

  computed: {
    ...mapGetters('reminder', ['getQueryParams']),
    ...mapState('reminder', {
      folders: state => state.folders,
      reminders: state => state.reminders,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolderIndex];
    },

    curReminder() {
      return this.curFolder ? this.reminders[this.curReminderIndex] : null;
    }
  },

  methods: {
    ...mapMutations('reminder', ['setQueryParams']),
    ...mapActions('reminder', [
      'getReminders',
      'deleteReminder',
      'copyReminder',
      'deleteFolder',
      'createFolder',
      'updateFolder',
      'getFolders',
    ]),

    forceRerender() {
      this.contentKey++;
    },

    tags(strtag) {
      return typeof strtag === 'string' ? (strtag.length > 0 ? strtag.split(',') : []) : strtag;
    },

    onSelectedFolderChanged(index) {
      this.selectedFolderIndex = index;
      this.isPc = true;
      this.queryParams.page = 1;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    async submitUpdateFolder(folder) {
      const response = await this.updateFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの変更は完了しました。');
      } else {
        window.toastr.error('フォルダーの変更は失敗しました。');
      }
    },

    async submitCreateFolder(folder) {
      const response = await this.createFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの作成は完了しました。');
      } else {
        window.toastr.error('フォルダーの作成は失敗しました。');
      }
    },

    async submitDeleteFolder() {
      const response = await this.deleteFolder(this.folders[this.selectedFolderIndex].id);
      if (response) {
        window.toastr.success('フォルダーの削除は完了しました。');
        this.onSelectedFolderChanged(0);
      } else {
        window.toastr.error('フォルダーの削除は失敗しました。');
      }
    },

    async submitDeleteReminder() {
      const response = await this.deleteReminder(this.curReminder.id);
      const url = `${this.rootPath}/user/reminders?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('リマインダの削除は完了しました。', url);
      } else {
        window.toastr.error('リマインダの削除は失敗しました。');
      }
      this.forceRerender();
    },

    async submitCopyReminder() {
      const response = await this.copyReminder(this.curReminder.id);
      const url = `${this.rootPath}/user/reminders?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('リマインダのコピーは完了しました。', url);
      } else {
        Util.showErrorThenRedirect('リマインダのコピーは失敗しました。', url);
      }
      this.forceRerender();
    },

    backToFolder() {
      this.isPc = false;
    },

    formattedDate(date) {
      return Util.formattedDate(date);
    },

    async loadPage() {
      this.$nextTick(async() => {
        this.setQueryParams(this.queryParams);
        this.loading = true;
        await this.getReminders();
        this.forceRerender();
        this.loading = false;
      });
    },
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <reorder-folder-left
          type="survey"
          :data="folders"
          :isPc="isPc"
          :selectedFolder="selectedFolderIndex"
          @changeSelectedFolder="changeSelectedFolder"
          @submitUpdateFolder="submitUpdateFolder"
          @submitCreateFolder="submitCreateFolder"
          @reorder="onDragFolderEnd"
        ></reorder-folder-left>
        <div class="flex-grow-1 folder-right" :key="contentKey">
          <div class="col-r d-flex">
            <a
                :href="`${rootPath}/user/surveys/new?folder_id=${curFolder ? curFolder.id : null}`"
                class="btn btn-primary"
            >
              <i class="uil-plus"></i> 新規作成
            </a>
            <div class="ml-auto d-flex">
              <div class="input-group app-search">
                <input
                    type="text"
                    class="form-control dropdown-toggle fw-250"
                    placeholder="検索..."
                    v-model="queryParams.name_cont"
                />
                <span class="mdi mdi-magnify search-icon"></span>
                <div class="input-group-append">
                  <div class="btn btn-primary" @click="search()">検索</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="overflow-auto table-content">
              <table class="table table-centered mb-0">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th>状況</th>
                    <th>フォーム名</th>
                    <th>回答状態</th>
                    <th></th>
                    <th class="mw-130"></th>
                  </tr>
                </thead>
                <draggable
                  tag="tbody"
                  v-model="surveysCopy"
                  @end="onDragSurveyEnd"
                  drag-class="dragging-item"
                  ghost-class="destination-item"
                >
                    <tr
                      v-for="(survey, index) in surveysCopy"
                      :key="survey.id"
                      class="draggable-row"
                    >
                    <td class="drag-icon-cell">
                      <i class="mdi mdi-drag text-secondary"></i>
                    </td>
                    <td class="mw-90 mxw-120">
                      <survey-status :status="survey.status"></survey-status>
                    </td>
                    <td>
                      <div class="mw-150 mxw-250 max-2-lines">{{ survey.name }}</div>
                    </td>
                    <td class="mw-90">
                      <template v-if="survey.responses_count === 0"> 未回答 </template>
                      <template v-else>
                        {{ survey.responses_count }}回答／<span class="font-12">{{ survey.users_count }}人</span>
                        <a :href="`${rootPath}/user/surveys/${survey.id}`" class="btn btn-sm btn-light ml-2">表示</a>
                      </template>
                      <a :href="survey.ggsheet_url" v-if="survey.ggsheet_url" target="_blank" class="fw-100"><img src="/img/ggsheet.png" width="35"/></a>
                    </td>
                    <td class="mw-210">
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-light btn-sm dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          操作 <span class="caret"></span>
                        </button>
                        <div class="dropdown-menu">
                          <a
                            role="button"
                            class="dropdown-item"
                            :href="`${rootPath}/user/surveys/${survey.id}/edit`"
                            v-if="survey.status !== 'published' && survey.editable"
                            >回答フォームを編集</a
                          >
                          <a
                            role="button"
                            class="dropdown-item"
                            :href="`${rootPath}/user/surveys/${survey.id}/export`"
                            >回答一覧DL</a
                          >
                          <a
                            role="button"
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#modalCopySurvey"
                            @click="curSurveyIndex = index"
                            >回答フォームをコピー</a
                          >
                          <a
                            role="button"
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#modalToggleSurvey"
                            @click="curSurveyIndex = index"
                            v-if="survey.status !== 'draft'"
                            >{{ survey.status === "published" ? "非公開" : "公開" }}にする</a
                          >
                          <a
                            role="button"
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#modalDeleteSurvey"
                            @click="curSurveyIndex = index"
                            v-if="survey.destroyable"
                            >回答フォームを削除</a
                          >
                        </div>
                      </div>
                      <div
                        class="btn btn-sm btn-light my-1"
                        data-toggle="modal"
                        data-target="#modalSurveyPreview"
                        @click="curSurveyIndex = index"
                      >
                        プレビュー
                      </div>
                    </td>
                    <td class="">
                      <div class="actions ml-auto">
                        <button
                          @click.stop="moveSurvey(index, 'up')"
                          :class="{ disabled: index === 0 && queryParams.page === 1 }"
                          class="btn btn-light btn-sm action-button"
                          :disabled="index === 0 && queryParams.page === 1"
                        >
                          <i class="dripicons-chevron-up"></i>
                        </button>
                        <button
                          @click.stop="moveSurvey(index, 'down')"
                          :class="{ disabled: index === surveysCopy.length - 1 && queryParams.page === totalPages }"
                          class="btn btn-light btn-sm action-button"
                          :disabled="index === surveysCopy.length - 1 && queryParams.page === totalPages"
                        >
                          <i class="dripicons-chevron-down"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
            <div class="d-flex justify-content-center mt-3 text-center">
              <b-pagination
                v-if="totalRows > perPage"
                v-model="queryParams.page"
                :total-rows="totalRows"
                :per-page="perPage"
                @change="loadPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
            <div class="text-center mt-5" v-if="!loading && totalRows === 0">
              <b>回答フォームはありません。</b>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-indicator :loading="loading"></loading-indicator>

    <!-- START: Delete folder modal -->
    <modal-confirm
      id="modalDeleteFolder"
      title="このフォルダーを削除してもよろしいですか？"
      type="delete"
      @confirm="submitDeleteFolder"
    >
      <template v-slot:content v-if="curFolder">
        <span>フォルダ名：{{ curFolder.name }}</span>
      </template>
    </modal-confirm>
    <!-- END: Delete folder modal -->

    <modal-confirm
      title="この回答フォームをコピーしてもよろしいですか？"
      id="modalCopySurvey"
      type="confirm"
      @confirm="submitCopySurvey"
    >
      <template v-slot:content v-if="curSurvey">
        <span>回答フォーム名：{{ curSurvey.name }}</span>
      </template>
    </modal-confirm>
    <modal-confirm
      title="この回答フォームを削除してもよろしいですか？"
      id="modalDeleteSurvey"
      type="delete"
      @confirm="submitDeleteSurvey"
    >
      <template v-slot:content v-if="curSurvey">
        <span>回答フォーム名：{{ curSurvey.name }}</span>
      </template>
    </modal-confirm>

    <!-- START: modal enable/disable richmenu -->
    <modal-confirm
      :title="`この回答フォームの状況を変更してもよろしいですか？`"
      id="modalToggleSurvey"
      type="confirm"
      @confirm="submitToggleSurvey"
    >
      <template v-slot:content v-if="curSurvey">
        状況変更：<b>{{ curSurvey.status === "published" ? "公開" : "非公開" }}</b>
        <i class="mdi mdi-arrow-right-bold"></i> <b>{{ curSurvey.status === "published" ? "非公開" : "公開" }}</b>
      </template>
    </modal-confirm>
    <!-- END: modal delete richmenu -->

    <!-- START: modal survey preview -->
    <modal-survey-preview :survey_id="curSurvey.id" v-if="curSurvey"></modal-survey-preview>
    <!-- END: modal survey preview -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Util from '@/core/util';

export default {
  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      loading: true,
      contentKey: 0,
      surveysData: [],
      selectedFolderIndex: 0,
      curSurveyIndex: 0,
      surveyContents: 0,
      survey: null,
      queryParams: {
        name_cont: null
      },
      surveysCopy: []
    };
  },

  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },

  async beforeMount() {
    await this.getFolders({ is_filter: true });
    const folderId = Number.parseInt(Util.getParamFromUrl('folder_id'));
    const index = folderId > 0 ? _.findIndex(this.folders, _ => _.id === folderId) : 0;
    this.changeSelectedFolder(index > 0 ? index : 0);
    this.loading = false;
  },

  computed: {
    ...mapGetters('survey', ['getQueryParams']),
    ...mapState('survey', {
      folders: state => state.folders,
      surveys: state => state.surveys,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolderIndex];
    },

    curSurvey() {
      return this.curFolder ? this.surveys[this.curSurveyIndex] : null;
    },

    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    }
  },

  methods: {
    ...mapMutations('survey', ['setQueryParams', 'updateFolderOrder', 'updateSurveyOrder']),
    ...mapActions('survey', [
      'createFolder',
      'updateFolder',
      'deleteFolder',
      'getSurveys',
      'copySurvey',
      'deleteSurvey',
      'toggleStatus',
      'getFolders',
      'reorderFolders',
      'reorderSurveys'
    ]),

    async onDragFolderEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.folders,
        this.reorderFolders,
        this.updateFolderOrder
      );
    },

    async moveSurvey(index, direction) {
      this.queryParams.page = await Util.moveItem(
        this.surveys,
        index,
        direction,
        this.reorderSurveys.bind(this),
        this.updateSurveyOrder.bind(this),
        null,
        this.queryParams.page,
        this.totalPages
      );

      await this.loadPage();
    },

    async onDragSurveyEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.surveys,
        this.reorderSurveys,
        this.updateSurveyOrder
      );

      await this.getSurveys();
    },

    forceRerender() {
      this.contentKey++;
    },

    changeSelectedFolder(index) {
      this.selectedFolderIndex = index;
      this.isPc = true;
      this.queryParams.page = 1;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    async submitUpdateFolder(folder) {
      const response = await this.updateFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの変更は完了しました。');
      } else {
        window.toastr.error('フォルダーの変更は失敗しました。');
      }
    },

    async submitCreateFolder(folder) {
      const response = await this.createFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの作成は完了しました。');
      } else {
        window.toastr.error('フォルダーの作成は失敗しました。');
      }
    },

    updateStatus(survey) {},

    async submitCopySurvey() {
      const response = await this.copySurvey(this.curSurvey.id);
      const url = `${this.rootPath}/user/surveys?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('回答フォームのコピーは完成しました。', url);
      } else {
        window.toastr.error('回答フォームのコピーは失敗しました。');
      }
    },

    async submitDeleteSurvey() {
      if (!this.curSurvey) return;
      const response = await this.deleteSurvey(this.curSurvey.id);
      const url = `${this.rootPath}/user/surveys?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('回答フォームの削除は完了しました。', url);
      } else {
        window.toastr.error('回答フォームの削除は失敗しました。');
      }
    },

    async submitToggleSurvey() {
      if (!this.curSurvey) return;
      const response = await this.toggleStatus(this.curSurvey.id);
      const url = `${this.rootPath}/user/surveys?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('回答フォーム状況の変更は完了しました。', url);
      } else {
        window.toastr.error('回答フォーム状況の変更は失敗しました。');
      }
    },

    async submitDeleteFolder() {
      const response = await this.deleteFolder(this.curFolder.id);
      if (response) {
        window.toastr.success('フォルダーの削除は完了しました。');
        this.changeSelectedFolder(0);
      } else {
        window.toastr.error('フォルダーの削除は失敗しました。');
      }
    },

    async search() {
      this.queryParams.page = 1;
      await this.loadPage(true);
    },

    async loadPage(isLoadFolder = false) {
      this.$nextTick(async() => {
        this.setQueryParams(this.queryParams);
        this.loading = true;
        if (isLoadFolder === true) {
          await this.getFolders({ is_filter: true });
        }
        await this.getSurveys({ show_draft: true });
        this.forceRerender();
        this.surveysCopy = _.cloneDeep(this.surveys);
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.dragging-item {
  opacity: 0.8;
  border: 2px solid #008a00;
}
.destination-item {
  opacity: 0.5;
  border: 2px dashed #6a0dad;
  background-color: #d8bfd8;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mart-setting d-flex align-items-center mt-3 mb-1"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"w-150"},[(_vm.markSelected)?_c('button',{staticClass:"btn btn-light btn-xs box-shadow-none",style:({
        backgroundColor: _vm.markSelected.color,
        color: _vm.getInversionColor(_vm.markSelected.color),
        inlineSize: 'max-content'
      })},[_vm._v("\n      "+_vm._s(_vm.markSelected.name)+"\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing),expression:"editing"}],staticClass:"ml-auto btn-group"},[_vm._m(1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.marks.length > 0),expression:"marks.length > 0"}],staticClass:"dropdown-menu"},[_vm._l((_vm.marks),function(mark,idx){return _c('a',{key:idx,staticClass:"dropdown-item d-flex px-2 align-items-center",attrs:{"role":"button"},on:{"click":function($event){return _vm.onSelectMark(mark)}}},[_c('div',{style:({
        width: '15px',
        height: '15px',
        marginRight: '5px',
        backgroundColor: mark.color,
        color: _vm.getInversionColor(mark.color),
      })}),_vm._v("\n      "+_vm._s(_vm._f("truncate")(mark.name,10))+"\n    ")])}),_vm._v(" "),_vm._m(2)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-150"},[_c('strong',[_vm._v("対応マーク")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-light btn-sm dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-expanded":"false"}},[_vm._v("\n      变更 "),_c('span',{staticClass:"caret"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item d-flex px-2 align-items-center border-top mt-1",attrs:{"href":"/user/marks?showBtnBack=1"}},[_c('i',{staticClass:"mdi mdi-link-variant-plus mr-1"}),_vm._v("\n      対応マーク設定\n    ")])
}]

export { render, staticRenderFns }
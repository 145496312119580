<template>
  <div>
    <div class="card">
      <div class="card-header left-border">
        <h3 class="card-title">注意</h3>
      </div>
      <div class="card-body">
        <nav class="navbar navbar-light bg-light">
          <ul class="my-2">
            <li>対応マークが上書きされる場合があります。</li>
            <li>友だち情報は移行できません。</li>
            <li>引き継ぎコードは発行から24時間有効です。</li>
            <li>同一の引き継ぎコードで複数回のデータ移行はできません。</li>
            <li>処理中にタグやテンプレートを編集しないでください。</li>
          </ul>
        </nav>
      </div>
    </div>

    <section :class="{ 'disabled-event': isPreview }">
      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「タグ」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.tagData.type" class="mr-1" type="radio" name="tagDataType" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.tagData.type" type="radio" name="tagDataType" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.tagData.type === 'select'">
            <div class="list-checkbox-tag">
              <input-tag :tags="dataTransfer.tagData.tags" @input="addListTag" />
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「友だち情報欄」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.variableData.type" class="mr-1" type="radio" name="variableDataType" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.variableData.type" type="radio" name="variableDataType" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.variableData.type === 'select'">
            <div class="select-variable-btn-wrapper">
              <div
                v-for="(variable, index) in dataTransfer.variableData.variables"
                :key="index"
                class="btn btn-secondary mw-170 mr-2 mb-2"
              >
                {{ variable.name | truncate(20) }}
                <span class="font-weight-bold float-right m-0 ml-1 h5" aria-hidden="true" @click="deleteVariable(index)" v-if="!isPreview">×</span>
              </div>
            </div>
            <div class="select-variable-btn-wrapper">
              <div
                class="btn btn-primary btn-sm mt-1"
                data-toggle="modal"
                v-if="!isPreview"
                data-target="#modal-select-variable"
              >
              選択する
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「カスタム検索」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.filterData.type" class="mr-1" type="radio" name="" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.filterData.type" type="radio" name="" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.filterData.type === 'select'">
            <div class="select-filter-btn-wrapper">
              <div
                v-for="(filter, index) in dataTransfer.filterData.filters"
                :key="index"
                class="btn btn-secondary mw-170 mr-2 mb-2"
              >
                {{ filter.name | truncate(20) }}
                <span class="font-weight-bold float-right m-0 ml-1 h5" aria-hidden="true" @click="deleteFilter(index)" v-if="!isPreview">×</span>
              </div>
            </div>
            <div class="select-filter-btn-wrapper">
              <div
                class="btn btn-primary btn-sm mt-1"
                data-toggle="modal"
                v-if="!isPreview"
                data-target="#modal-select-filter"
              >
              選択する
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「シナリオ」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.scenarioData.type" class="mr-1" type="radio" name="" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.scenarioData.type" type="radio" name="" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.scenarioData.type === 'select'">
            <div class="select-scenario-btn-wrapper">
              <div
                v-for="(scenario, index) in dataTransfer.scenarioData.scenarios"
                :key="index"
                class="btn btn-secondary mw-170 mr-2 mb-2"
              >
                {{ scenario.title | truncate(20) }}
                <span class="font-weight-bold float-right m-0 ml-1 h5" aria-hidden="true" @click="deleteScenario(index)" v-if="!isPreview">×</span>
              </div>
            </div>
            <div class="select-scenario-btn-wrapper">
              <div
                class="btn btn-primary btn-sm mt-1"
                data-toggle="modal"
                v-if="!isPreview"
                data-target="#modal-select-scenario"
              >
              選択する
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「テンプレート」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.templateData.type" class="mr-1" type="radio" name="" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.templateData.type" type="radio" name="" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.templateData.type === 'select'">
            <div class="select-template-btn-wrapper">
              <div
                v-for="(template, index) in dataTransfer.templateData.templates"
                :key="index"
                class="btn btn-secondary mw-170 mr-2 mb-2"
              >
                {{ template.name | truncate(20) }}
                <span class="font-weight-bold float-right m-0 ml-1 h5" aria-hidden="true" @click="deleteTemplate(index)" v-if="!isPreview">×</span>
              </div>
            </div>
            <div class="select-template-btn-wrapper">
              <div
                class="btn btn-primary btn-sm mt-1"
                data-toggle="modal"
                v-if="!isPreview"
                data-target="#modal-select-template"
              >
              選択する
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「回答フォーム」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.surveyData.type" class="mr-1" type="radio" name="" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.surveyData.type" type="radio" name="" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.surveyData.type === 'select'">
            <div class="select-survey-btn-wrapper">
              <div
                v-for="(survey, index) in dataTransfer.surveyData.surveys"
                :key="index"
                class="btn btn-secondary mw-170 mr-2 mb-2"
              >
                {{ survey.name | truncate(20) }}
                <span class="font-weight-bold float-right m-0 ml-1 h5" aria-hidden="true" @click="deleteSurvey(index)" v-if="!isPreview">×</span>
              </div>
            </div>
            <div class="select-survey-btn-wrapper">
              <div
                class="btn btn-primary btn-sm mt-1"
                data-toggle="modal"
                v-if="!isPreview"
                data-target="#modal-select-survey"
              >
              選択する
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「自動応答」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.autoResponseData.type" class="mr-1" type="radio" name="" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.autoResponseData.type" type="radio" name="" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.autoResponseData.type === 'select'">
            <div class="select-auto-response-btn-wrapper">
              <div
                v-for="(autoResponse, index) in dataTransfer.autoResponseData.autoResponses"
                :key="index"
                class="btn btn-secondary mw-170 mr-2 mb-2"
              >
                {{ autoResponse.name | truncate(20) }}
                <span class="font-weight-bold float-right m-0 ml-1 h5" aria-hidden="true" @click="deleteAutoResponse(index)" v-if="!isPreview">×</span>
              </div>
            </div>
            <div class="select-auto-response-btn-wrapper">
              <div
                class="btn btn-primary btn-sm mt-1"
                data-toggle="modal"
                v-if="!isPreview"
                data-target="#modal-select-auto-response"
              >
              選択する
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「リマインダ」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.reminderData.type" class="mr-1" type="radio" name="" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.reminderData.type" type="radio" name="" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.reminderData.type === 'select'">
            <div class="select-reminder-btn-wrapper">
              <div
                v-for="(reminder, index) in dataTransfer.reminderData.reminders"
                :key="index"
                class="btn btn-secondary mw-170 mr-2 mb-2"
              >
                {{ reminder.name | truncate(20) }}
                <span class="font-weight-bold float-right m-0 ml-1 h5" aria-hidden="true" @click="deleteReminder(index)" v-if="!isPreview">×</span>
              </div>
            </div>
            <div class="select-reminder-btn-wrapper">
              <div
                class="btn btn-primary btn-sm mt-1"
                data-toggle="modal"
                v-if="!isPreview"
                data-target="#modal-select-reminder"
              >
              選択する
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「リッチメニュー」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.richMenuData.type" class="mr-1" type="radio" name="" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.richMenuData.type" type="radio" name="" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.richMenuData.type === 'select'">
            <div class="select-rich-menu-btn-wrapper">
              <div
                v-for="(richMenu, index) in dataTransfer.richMenuData.richMenus"
                :key="index"
                class="btn btn-secondary mw-170 mr-2 mb-2"
              >
                {{ richMenu.name | truncate(20) }}
                <span class="font-weight-bold float-right m-0 ml-1 h5" aria-hidden="true" @click="deleteRichMenu(index)" v-if="!isPreview">×</span>
              </div>
            </div>
            <div class="select-rich-menu-btn-wrapper">
              <div
                class="btn btn-primary btn-sm mt-1"
                data-toggle="modal"
                v-if="!isPreview"
                data-target="#modal-select-rich-menu"
              >
              選択する
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header left-border">
          <h3 class="card-title">「流入経路」</h3>
        </div>
        <div class="card-body">
          <div class="radio-group">
            <label><input v-model="dataTransfer.streamRouteData.type" class="mr-1" type="radio" name="" value="all"/>全データ</label>
            <label><input v-model="dataTransfer.streamRouteData.type" type="radio" name="" value="select" />個別選択</label>
          </div>
          <div v-show="dataTransfer.streamRouteData.type === 'select'">
            <div class="select-stream-route-btn-wrapper">
              <div
                v-for="(streamRoute, index) in dataTransfer.streamRouteData.streamRoutes"
                :key="index"
                class="btn btn-secondary mw-170 mr-2 mb-2"
              >
                {{ streamRoute.name | truncate(20) }}
                <span class="font-weight-bold float-right m-0 ml-1 h5" aria-hidden="true" @click="deleteStreamRoute(index)" v-if="!isPreview">×</span>
              </div>
            </div>
            <div class="select-stream-route-btn-wrapper">
              <div
                class="btn btn-primary btn-sm mt-1"
                data-toggle="modal"
                v-if="!isPreview"
                data-target="#modal-select-stream-route"
              >
              選択する
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="mb-5">
      <button v-if="isPreview" class="float-right btn btn-success" @click="transferNow">この条件で引き継ぎを実行</button>
      <button v-else class="float-right btn btn-success" @click="submit">この条件で引き継ぎコードを発行</button>
    </div>

    <modal-select-variable type="all" id="modal-select-variable" @selectVariable="selectVariable" />
    <modal-select-scenario type="all" id="modal-select-scenario" @selectScenario="selectScenario" />
    <modal-select-template type="all" id="modal-select-template" @selectTemplate="selectTemplate" />
    <modal-select-survey type="all" id="modal-select-survey" @selectSurvey="selectSurvey" />
    <modal-select-auto-response type="all" id="modal-select-auto-response" @selectAutoResponse="selectAutoResponse" />
    <modal-select-reminder type="all" id="modal-select-reminder" @selectReminder="selectReminder" />
    <modal-select-rich-menu type="all" id="modal-select-rich-menu" @selectRichMenu="selectRichMenu" />
    <modal-select-stream-route type="all" id="modal-select-stream-route" @selectStreamRoute="selectStreamRoute" />
    <modal-select-filter @selectFilter="selectFilter" id="modal-select-filter" />
  </div>
</template>
<script>
import Util from '@/core/util';
import { mapActions } from 'vuex';

export default {
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    defaultCode: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      dataTransfer: {
        tagData: {
          type: 'all',
          tags: []
        },
        variableData: {
          type: 'all',
          variables: []
        },
        scenarioData: {
          type: 'all',
          scenarios: []
        },
        templateData: {
          type: 'all',
          templates: []
        },
        surveyData: {
          type: 'all',
          surveys: []
        },
        autoResponseData: {
          type: 'all',
          autoResponses: []
        },
        reminderData: {
          type: 'all',
          reminders: []
        },
        richMenuData: {
          type: 'all',
          richMenus: []
        },
        streamRouteData: {
          type: 'all',
          streamRoutes: []
        },
        filterData: {
          type: 'all',
          filters: []
        }
      },
      showErrorThenRedirect: Util.showErrorThenRedirect
    };
  },

  beforeMount() {
    if (this.defaultCode) {
      this.getDataTransfer(this.defaultCode).then(res => {
        if (res) {
          this.dataTransfer = res.data;
        } else {
          this.showErrorThenRedirect(
            'このトランスファーコードはもう利用できません。',
            `${process.env.MIX_ROOT_PATH}/user/data_transfers`
          );
        }
      });
    }
  },

  methods: {
    ...mapActions('dataTransfer', ['createDataTransfer', 'getDataTransfer', 'transfer']),

    addListTag(data) {
      this.dataTransfer.tagData.tags = data;
    },

    selectVariable(data) {
      if (this.dataTransfer.variableData.variables.find(x => x.id === data.id)) return;
      this.dataTransfer.variableData.variables.push(data);
    },

    selectScenario(data) {
      if (this.dataTransfer.scenarioData.scenarios.find(x => x.id === data.id)) return;
      this.dataTransfer.scenarioData.scenarios.push(data);
    },

    selectTemplate(data) {
      if (this.dataTransfer.templateData.templates.find(x => x.id === data.id)) return;
      this.dataTransfer.templateData.templates.push(data);
    },

    selectSurvey(data) {
      if (this.dataTransfer.surveyData.surveys.find(x => x.id === data.id)) return;
      this.dataTransfer.surveyData.surveys.push(data);
    },

    selectAutoResponse(data) {
      if (this.dataTransfer.autoResponseData.autoResponses.find(x => x.id === data.id)) return;
      this.dataTransfer.autoResponseData.autoResponses.push(data);
    },

    selectReminder(data) {
      if (this.dataTransfer.reminderData.reminders.find(x => x.id === data.id)) return;
      this.dataTransfer.reminderData.reminders.push(data);
    },

    selectRichMenu(data) {
      if (this.dataTransfer.richMenuData.richMenus.find(x => x.id === data.id)) return;
      this.dataTransfer.richMenuData.richMenus.push(data);
    },

    selectStreamRoute(data) {
      if (this.dataTransfer.streamRouteData.streamRoutes.find(x => x.id === data.id)) return;
      this.dataTransfer.streamRouteData.streamRoutes.push(data);
    },

    selectFilter(data) {
      if (this.dataTransfer.filterData.filters.find(x => x.id === data.id)) return;
      this.dataTransfer.filterData.filters.push(data);
    },

    deleteVariable(index) {
      this.dataTransfer.variableData.variables.splice(index, 1);
    },

    deleteScenario(index) {
      this.dataTransfer.scenarioData.scenarios.splice(index, 1);
    },

    deleteTemplate(index) {
      this.dataTransfer.templateData.templates.splice(index, 1);
    },

    deleteSurvey(index) {
      this.dataTransfer.surveyData.surveys.splice(index, 1);
    },

    deleteAutoResponse(index) {
      this.dataTransfer.autoResponseData.autoResponses.splice(index, 1);
    },

    deleteReminder(index) {
      this.dataTransfer.reminderData.reminders.splice(index, 1);
    },

    deleteRichMenu(index) {
      this.dataTransfer.richMenuData.richMenus.splice(index, 1);
    },

    deleteStreamRoute(index) {
      this.dataTransfer.streamRouteData.streamRoutes.splice(index, 1);
    },

    deleteFilter(index) {
      this.dataTransfer.filterData.filters.splice(index, 1);
    },

    async submit() {
      await this.createDataTransfer({ data: this.dataTransfer });
      window.location.href = `${process.env.MIX_ROOT_PATH}/user/data_transfers`;
    },

    async transferNow() {
      await this.transfer(this.defaultCode);
      window.location.href = `${process.env.MIX_ROOT_PATH}/user`;
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled-event {
  pointer-events: none;
}
</style>

<template>
  <div class="container mt-4">
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="font-weight-bold">フィルター詳細</h4>
        <p class="text-muted">フィルターに関する詳細情報を確認できます。</p>
      </div>
    </div>

    <filter-info-detail :filterData="filterData" />

    <!-- Conditions -->
    <div class="row mt-4">
      <div class="col-12">
        <b-card>
          <h5 class="font-weight-bold">条件</h5>
          <hr />
          <div class="row">
            <div
              class="col-md-6 condition-column"
              v-for="(conditions, type) in mappedConditions"
              :key="type"
            >
              <h6>{{ type === 'and' ? 'AND条件' : 'OR条件' }}</h6>
              <ul class="list-unstyled">
                <li
                  v-for="(conditionGroup, groupIndex) in conditions"
                  :key="`${type}-${groupIndex}`"
                  class="p-2 mb-2 border rounded bg-white"
                >
                  <ul class="list-unstyled">
                    <filter-condition-item-detail
                      v-for="(condition, index) in conditionGroup.selected_conditions"
                      :key="`condition-${index}`"
                      :condition="condition"
                      :mapOperatorName="mapOperatorName"
                      :renderConditionValue="renderConditionValue"
                    />
                  </ul>
                </li>
                <li v-if="!conditions.length" class="text-muted">
                  条件がありません。
                </li>
              </ul>
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <!-- Footer -->
    <div class="row mt-4">
      <div class="col-12 text-center">
        <b-button variant="primary" @click="goBack">戻る</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  props: {
    filter_id: Number
  },
  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      filterData: {
        name: '',
        folder_name: '',
        display_order: '',
        display_count: null,
        show_blocked_users: false,
        show_hidden_users: false,
        created_at: '',
        updated_at: '',
        conditions: { and: [], or: [] }
      },
      mappedConditions: { and: [], or: [] }
    };
  },
  async beforeMount() {
    try {
      const response = await this.getFilter(this.filter_id);
      this.filterData = response;
      if (response.conditions) {
        this.mapConditions(response.conditions);
      } else {
        this.mappedConditions = { and: [], or: [] };
      }
    } catch (error) {
      console.error('Failed to fetch filter data:', error);
    }
  },
  methods: {
    ...mapActions('filter', ['getFilter']),
    formatDateTime(time) {
      return time ? moment(time).format('YYYY年MM月DD日 HH:mm') : 'N/A';
    },
    goBack() {
      window.location.href = `${this.rootPath}/user/filters`;
    },
    mapConditions(conditions) {
      conditions.forEach((condition) => {
        if (!condition.selected_conditions || condition.selected_conditions.length === 0) return;
        this.mappedConditions[condition.type === 'or' ? 'or' : 'and'].push(condition);
      });
    },
    renderConditionValue(condition) {
      switch (condition.key_name) {
      case 'name':
      case 'personal_note':
        return condition.value || 'N/A';
      case 'friend_registration_date':
        return condition
          ? `開始: ${condition.value || 'N/A'}, 終了: ${condition.second_value || 'N/A'}`
          : 'N/A';
      case 'mark':
      case 'tag':
      case 'infor_line_friend':
        return condition.items && condition.items.length
          ? condition.items.map((option) => option.text).join(', ')
          : 'N/A';
      default:
        return '不明な条件';
      }
    },
    mapOperatorName(operator) {
      const operatorOptions = [
        { value: '=', text: '等しい (equal)' },
        { value: '!=', text: '等しくない (not equal)' },
        { value: 'like', text: '部分一致 (contains)' },
        { value: 'not_like', text: '部分不一致 (does not contain)' },
        { value: 'between', text: '範囲 (between)' },
        { value: 'not_between', text: '範囲外 (not between)' },
        { value: 'greater_than', text: 'より大きい (greater than)' },
        { value: 'less_than', text: 'より小さい (less than)' }
      ];

      const matchedOption = operatorOptions.find((option) => option.value === operator);
      return matchedOption ? matchedOption.text : '不明な条件';
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
}
h4,
h5 {
  color: #343a40;
}
.text-muted {
  font-size: 14px;
}
.condition-column {
  padding: 16px;
  margin-bottom: 16px;
}
</style>

<template>
    <div>
      <label class="font-weight-bold mb-1">
        {{ label }} <required-mark />
      </label>
      <component
        :is="inputComponent"
        v-model="value"
        :placeholder="placeholder"
        class="w-100 mb-2"
        v-validate="validationRules"
        :name="name"
        :locale="locale"
        :timezone="timezone"
        :date-format-options="dateFormatOptions"
        :time-format-options="timeFormatOptions"
        :show-seconds="showSeconds"
      ></component>
      <span v-if="errorMessage" class="text-danger">
        {{ errorMessage }}
      </span>
    </div>
  </template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: '値を入力してください'
    },
    name: {
      type: String,
      required: true
    },
    validationRules: {
      type: String,
      default: 'required'
    },
    errorMessage: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: 'ja'
    },
    timezone: {
      type: String,
      default: 'Asia/Tokyo'
    },
    dateFormatOptions: {
      type: Object,
      default: () => ({})
    },
    timeFormatOptions: {
      type: Object,
      default: () => ({})
    },
    showSeconds: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputComponent() {
      switch (this.type) {
      case 'date':
        return 'b-form-datepicker';
      case 'datetime':
        return 'b-form-datetimepicker';
      case 'time':
        return 'b-form-timepicker';
      default:
        return 'b-form-input'; // Default to text input
      }
    }
  }
};
</script>
<template>
  <div>
    <!-- Header -->
    <div class="mb-3">
      <label class="font-weight-bold">管理用の名前 <required-mark /></label>
      <b-form-input
        v-model="filterName"
        data-vv-as="管理用の名前"
        placeholder="管理用の名前を入力"
        class="w-50"
        name="filterName"
        v-validate="'required|max:255'"
      ></b-form-input>
      <!-- Hiển thị lỗi -->
      <span v-if="errors.has('filterName')" class="text-danger">
        {{ errors.first('filterName') }}
      </span>

      <label class="font-weight-bold mt-2">フォルダー</label>
      <b-form-select
        :options="prodOptions"
        class="w-50"
        v-model="selectedFolderId"
        data-vv-as="フォルダー"
      ></b-form-select>
    </div>

    <!-- Conditions -->
    <div>
      <b-card
        v-for="(conditionGroup, conditionIndex) in displayConditions"
        :key="conditionIndex"
        class="mb-4"
      >
        <template #header>
          <h5 class="font-weight-bold m-0">
            {{
              conditionGroup.type === "and"
                ? '"すべて満たす" 必要がある条件 (and条件)'
                : '"いずれか1つ以上満たす" 必要がある条件 (or条件)'
            }}
          </h5>
        </template>

        <div v-if="getSelectedItems(conditionGroup.type).length > 0" class="mb-3">
          <h6>
            {{
              conditionGroup.type === "and"
                ? "選択された条件 (AND条件):"
                : "選択された条件 (OR条件):"
            }}
          </h6>
          <div class="d-flex flex-wrap">
            <div
              v-for="(selected, index) in getSelectedItems(conditionGroup.type)"
              :key="index"
              class="position-relative m-1"
              style="display: inline-block;"
            >
              <b-button variant="primary" class="pr-4 pl-3" disabled>
                {{ selected.text }}
              </b-button>
              <span class="btn-close" @click="removeItem(selected, conditionGroup.type)">×</span>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap">
          <b-button
            v-for="(item, keyIndex) in conditionGroup.conditions"
            :key="keyIndex"
            variant="outline-secondary"
            class="m-1"
            @click="addItem(item, conditionGroup.type)"
          >
            {{ item.text }}
          </b-button>
        </div>
      </b-card>
    </div>

    <!-- Block options -->
    <div class="d-flex justify-content-between mb-3 input-group-equal">
      <div>
        <label>ブロックされた人</label>
        <b-form-select v-model="showBlockedUsers" :options="booleanOptions" class="w-100"></b-form-select>
      </div>
      <div>
        <label>ブロック・非表示にした人</label>
        <b-form-select v-model="showHiddenUsers" :options="booleanOptions" class="w-100"></b-form-select>
      </div>
      <div>
        <label>表示順</label>
        <b-form-select v-model="selectedDisplayOrder" :options="displayOrder" class="w-100"></b-form-select>
      </div>
      <div>
        <label>表示数</label>
        <b-form-select v-model="selectedDisplayCount" :options="displayOptions" class="w-100"></b-form-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    folders: {
      type: Array,
      required: true
    },
    curFolder: {
      type: Object,
      required: true
    },
    displayConditions: {
      type: Array,
      required: true
    },
    booleanOptions: {
      type: Array,
      required: true
    },
    displayOrder: {
      type: Array,
      required: true
    },
    displayOptions: {
      type: Array,
      required: true
    },
    selectedAndItems: {
      type: Array,
      required: true
    },
    selectedOrItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filterName: '',
      selectedFolderId: null,
      selectedDisplayOrder: 'asc',
      selectedDisplayCount: 50,
      showBlockedUsers: false,
      showHiddenUsers: false
    };
  },
  computed: {
    prodOptions() {
      return this.folders.map(({ id, name }) => ({
        value: id,
        text: name
      }));
    }
  },
  watch: {
    curFolder: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.id) {
          this.selectedFolderId = newVal.id;
        }
      }
    }
  },
  methods: {
    addItem(item, type) {
      if (type === 'and') {
        this.selectedAndItems.push(item);
      } else if (type === 'or') {
        this.selectedOrItems.push(item);
      }
    },
    removeItem(item, type) {
      if (type === 'and') {
        this.selectedAndItems = this.selectedAndItems.filter(i => i !== item);
      } else if (type === 'or') {
        this.selectedOrItems = this.selectedOrItems.filter(i => i !== item);
      }
    },
    getSelectedItems(type) {
      return type === 'and' ? this.selectedAndItems : this.selectedOrItems;
    }
  }
};
</script>

<style scoped>
.input-group-equal > div {
  flex: 1;
  margin-right: 15px;
}

.input-group-equal > div:last-child {
  margin-right: 0;
}
</style>

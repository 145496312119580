<template>
  <div class="profile-detail row" v-if="friendData">
    <div class="col-xl-4 d-flex">
      <div class="card card-success card-outline w-100">
        <div class="card-body box-profile">
          <!-- profile image -->
          <div class="text-center">
            <img v-lazy="avatarImgObj" class="rounded-circle avatar-lg img-thumbnail" alt="profile-image" />
          </div>
          <!-- line user name -->
          <h3 class="profile-username text-center">{{ friendData.line_name }}</h3>

          <span class="d-flex flex-row w-100" v-if="isAdmin">
            <friend-toggle-visible
              :id="friendData.id"
              :visible="friendData.visible"
              class="ml-auto mr-1"
            ></friend-toggle-visible>
            <friend-toggle-locked
              :id="friendData.id"
              :locked="friendData.locked"
              class="mr-auto"
            ></friend-toggle-locked>
          </span>

          <ul class="list-group list-group-unbordered my-3">
            <!-- locked -->
            <li class="list-group-item">
              <b>ステータス</b>
              <span class="float-right"
                ><friend-status
                  :status="friendData.status"
                  :locked="friendData.locked"
                  :visible="friendData.visible"
                ></friend-status
              ></span>
            </li>

            <!-- go to chat -->
            <li class="list-group-item">
              <b>トーク</b
              ><a class="float-right" :href="`/user/channels/${channel_id}`"
                ><i class="uil-comment-alt-dots"></i> メッセージ</a
              >
            </li>

            <!-- friend addition time -->
            <li class="list-group-item">
              <b>登録日</b><span class="float-right">{{ friendData.created_at | formatted_time }}</span>
            </li>
          </ul>
        </div>
        <!-- /.card-body -->
        <loading-indicator :loading="loading"></loading-indicator>
      </div>
      <!-- /.card -->
    </div>
    <div class="col-xl-8">
      <div class="card card-success card-outline">
        <div class="card-header d-flex align-items-center">
          <h4>詳細情報</h4>
        </div>
        <div class="card-body">
          <p class="mb-1"><strong>表示名</strong></p>
          <p class="text-muted mt-2">
            <input
              type="text"
              placeholder="表示名"
              class="form-control"
              v-model.trim="friendData.display_name"
              name="display_name"
              ref="displayName"
              :disabled="!editing"
              v-validate="'max:255'"
              data-vv-as="表示名"
            />
            <error-message :message="errors.first('display_name')"></error-message>
          </p>
          <hr />

          <p class="mt-3 mb-1"><strong>メモ欄</strong></p>
          <p class="text-muted mt-2">
            <textarea
              rows="2"
              class="form-control"
              v-model.trim="friendData.note"
              name="note"
              :disabled="!editing"
              v-validate="'max:2000'"
              data-vv-as="メモ欄"
            ></textarea>
            <error-message :message="errors.first('note')"></error-message>
          </p>
          <hr />
          <mark-selection :editing="editing" @select="onSelectMark" :selected="friendData.mark ? friendData.mark.id : null"></mark-selection>
          <hr />

          <p class="mt-3 mb-1"><strong>タグ</strong></p>
          <p class="text-muted mt-2">
            <friend-tag :tags="friendData.tags" v-if="!editing"></friend-tag>
            <input-tag :tags="friendData.tags" @input="selectTags" :allTags="true" v-if="editing" />
          </p>
          <hr />
          <div class="flex-1 custom-control custom-checkbox mr-2">
            <input
              type="checkbox"
              class="custom-control-input"
              v-model="friendData.tester"
              value="1"
              name="tester"
              id="is_tester_account"
              :disabled="!editing"
            />
            <label class="custom-control-label" for="is_tester_account">テスター</label>
          </div>
          <hr />
          <div v-if="isAdmin && !editing" @click="editing = true" class="btn btn-success fw-120">編集</div>
          <div class="btn btn-success fw-120" @click="onSave()" v-show="editing">更新</div>
        </div>
        <loading-indicator :loading="loading"></loading-indicator>
      </div>
    </div>

    <div class="col-xl-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a href="#customInfo" data-toggle="tab" aria-expanded="true" class="nav-link active">
            <i class="mdi mdi-home-variant d-md-none d-block"></i>
            <span class="d-none d-md-block">友達情報</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#reminder" data-toggle="tab" aria-expanded="true" class="nav-link">
            <i class="mdi mdi-home-variant d-md-none d-block"></i>
            <span class="d-none d-md-block">リマインダ</span>
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <!-- 友達情報 -->
        <div class="tab-pane show active border border-light" id="customInfo">
          <div class="card m-0 p-0">
            <div class="card-body p-0">
              <table class="table table-striped table-centered">
                <tbody>
                  <tr v-for="(variable, index) in variablesData" :key="index">
                    <template v-if="!editingVariables">
                      <th>{{ variable.name }}</th>
                      <td v-if="variable.type == 'image' && variable.value && variable.file_url">
                        <div v-lazy:background-image="variable.file_url" class="fw-120 fh-81 background-cover"></div>
                      </td>
                      <td v-else-if="variable.type === 'pdf' && variable.value && variable.file_url">
                        <img :src="`${rootPath}/images/messages/pdf.png`" class="fw-120 fh-120 background-cover" />
                        <a class="btn btn-sm btn-light" :href="variable.file_url" download="lineinsight.pdf">ダウンロード</a>
                      </td>
                      <td v-else>{{ variable.value || "未設定" }}</td>
                    </template>
                    <template v-else>
                      <th class="w-25">{{ variable.name }}</th>
                      <td v-if="variable.type === 'image'">
                        <button class="btn btn-primary" @click="triggerFileInput(index, 'image')">
                          画像をアップロード
                        </button>
                        <input
                          type="file"
                          accept="image/*"
                          :ref="'fileInput_' + index"
                          style="display: none;"
                          @change="onFileUpload($event, variable, 'image')"
                        />
                        <span v-if="variable.value" class="ml-2">{{ variable.value.name || 'File uploaded' }}</span>
                      </td>
                      <td v-else-if="variable.type === 'pdf'">
                        <button class="btn btn-primary" @click="triggerFileInput(index, 'pdf')">
                          PDFをアップロード
                        </button>
                        <input
                          type="file"
                          accept="application/pdf"
                          :ref="'fileInput_' + index"
                          style="display: none;"
                          @change="onFileUpload($event, variable, 'pdf')"
                        />
                        <span v-if="variable.value" class="ml-2">{{ variable.value.name || 'File uploaded' }}</span>
                      </td>
                      <td v-else>
                        <input
                          type="text"
                          class="form-control"
                          v-model="variable.value"
                          placeholder="変数名を入力"
                          name="variableValue"
                          :id="'variable_value_' + index"
                        />
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
              <div class="text-center py-5 font-weight-bold" v-if="variables.length === 0">データはありません。</div>
            </div>
          </div>
          <div class="card" v-if="isAdmin">
            <div class="card-body">
              <div class="row">
                <div v-if="isAdmin && !editingVariables" @click="editingVariables = true" class="btn btn-success fw-120">編集</div>
                <div class="btn btn-success fw-120" @click="onSaveVariables()" v-show="editingVariables">更新</div>
              </div>
            </div>
          </div>
        </div>

        <!-- リマインダー -->
        <div class="tab-pane show border border-light" id="reminder">
          <div class="card" v-if="isAdmin">
            <div class="card-body">
              <div class="row">
                <label class="col-lg-3">リマインダ</label>
                <label class="col-lg-9">ゴール日</label>
              </div>
              <friend-assign-reminder :friend_id="friend_id"></friend-assign-reminder>
            </div>
          </div>

          <div class="card mt-2">
            <div class="card-header"><h5>最新リマインダ</h5></div>
            <div class="card-body py-0">
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th>リマインダ名</th>
                    <th>登録日時</th>
                    <th>ゴール</th>
                    <th>状況</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(reminder, index) in reminders" :key="index">
                    <td class="mw-200 max-2-lines">{{ reminder.name }}</td>
                    <td class="mw-150">{{ reminder.created_at | formatted_time }}</td>
                    <td class="mw-200">{{ reminder.goal | formatted_date }}</td>
                    <td class="mw-150"><reminding-status :status="reminder.status"></reminding-status></td>
                  </tr>
                </tbody>
              </table>

              <div v-if="reminders.length === 0" class="text-center my-3">リマインダがありません。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Util from '@/core/util';

export default {
  props: {
    friend_id: Number,
    channel_id: Number,
    role: String
  },
  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      friendData: {
        status: 'active',
        locked: null,
        visible: null,
        line_name: '',
        line_picture_url: '',
        display_name: '',
        note: '',
        tester: false,
        mark: null
      },
      variables: [],
      marks: [],
      variablesData: [],
      avatarImgObj: {
        src: '',
        error: '/img/no-image-profile.png',
        loading: '/images/loading.gif'
      },
      friendIndexPath: `${process.env.MIX_ROOT_PATH}/user/friends`,
      isShowDisplayName: false,
      field_index: null,
      currentTab: '友だち情報名',
      loading: true,
      editing: false,
      editingVariables: false
    };
  },

  async beforeMount() {
    const response = await this.getFriend(this.friend_id);
    this.friendData = _.cloneDeep(response);
    this.variables = await this.getVariables(this.friend_id);
    this.variablesData = _.cloneDeep(this.variables);
    await this.getReminders(this.friend_id);
    this.avatarImgObj.src = this.friendData.line_picture_url;
    this.marks = await this.getMarks();
    this.loading = false;
  },

  computed: {
    ...mapState('friend', {
      reminders: state => state.reminders
    }),

    isAdmin: function() {
      return this.role === 'admin';
    },

    isStaff: function() {
      return this.role === 'staff';
    }
  },

  methods: {
    ...mapActions('mark', ['getMarks']),
    ...mapActions('friend', ['getFriend', 'updateFriend', 'getReminders', 'getVariables', 'setMark', 'updateFriendVariableList']),

    selectTags(tags) {
      this.friendData.tags = tags;
    },

    getInversionColor(color) {
      return Util.getInversionColor(color);
    },

    async onSave() {
      const valid = await this.$validator.validateAll();
      if (!valid) return;
      this.loading = true;

      const updatedVariables = this.variablesData.filter(
        (variable, index) => variable.name !== this.variables[index].name
      );

      const formData = {
        id: this.friendData.id,
        display_name: this.friendData.display_name,
        note: this.friendData.note,
        tag_ids: this.friendData.tags ? this.friendData.tags.map(_ => _.id) : [],
        tester: this.friendData.tester,
        variables: updatedVariables
      };
      const response = await this.updateFriend(formData);
      if (response) {
        window.toastr.success('友だち情報の更新が成功しました。');
      } else {
        window.toastr.error('友だち情報の更新が失敗しました。');
      }
      this.editing = false;
      this.loading = false;
    },

    triggerFileInput(index, type) {
      const fileInput = this.$refs['fileInput_' + index][0];
      if (fileInput) {
        fileInput.click();
      }
    },

    async onSaveVariables() {
      this.loading = true;

      const formData = new FormData();

      const updatedVariables = this.variablesData.map((variable, index) => {
        const originalVariable = this.variables[index];
        return {
          ...variable,
          isChanged: !originalVariable || variable.value !== originalVariable.value,
        };
      });

      if (!updatedVariables.some((v) => v.isChanged)) {
        this.variablesData = _.cloneDeep(this.variables);
        window.toastr.success('変数の更新が成功しました。');
        this.editingVariables = false;
        this.loading = false;
        return;
      }

      formData.append('friend_id', this.friendData.id);

      updatedVariables.forEach((variable, index) => {
        if (variable.isChanged) {
          if (variable.value instanceof File) {
            formData.append(`variables[${index}][file]`, variable.value);
          } else {
            formData.append(`variables[${index}][value]`, variable.value);
          }
          formData.append(`variables[${index}][id]`, variable.id);
          formData.append(`variables[${index}][friend_variable_id]`, variable.friend_variable_id);
          formData.append(`variables[${index}][name]`, variable.name);
        }
      });

      try {
        const response = await this.updateFriendVariableList(formData);

        if (response) {
          this.variables = await this.getVariables(this.friend_id);
          this.variablesData = _.cloneDeep(this.variables);
          window.toastr.success('変数の更新が成功しました。');
        } else {
          window.toastr.error('変数の更新が失敗しました。');
        }
      } catch (error) {
        window.toastr.error('エラーが発生しました。');
        console.error(error);
      }

      this.editingVariables = false;
      this.loading = false;
    },

    onFileUpload(event, variable, type) {
      const file = event.target.files[0];
      if (!file) return;
      variable.value = file;
    },

    onSelectReminder() {
      // TODO
    },

    onSelectMark(mark) {
      this.setMark({
        friend_id: this.friend_id,
        mark_id: mark.id
      });
    }
  }
};
</script>

<style scoped lang="scss">
.mart-setting {
  .dropdown-menu {
    left: -42px !important;
  }
}

table input[type='file'] {
  width: 100%;
  padding: 10px;
  cursor: pointer;
}
</style>

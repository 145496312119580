
import FriendAPI from '../api/friend_api';
export const state = () => ({
  updateResults: []
});

export const actions = {
  async updateFriendVariableList(_, payload) {
    try {
      return await await FriendAPI.update_list(payload);
    } catch (error) {
      return null;
    }
  }
};

<template>
    <div>
      <b-card>
        <h5 class="font-weight-bold">フィルター情報</h5>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <p><strong>名前:</strong> {{ filterData.name || 'N/A' }}</p>
            <p><strong>フォルダー:</strong> {{ filterData.folder_name || 'N/A' }}</p>
            <p><strong>表示順:</strong> {{ filterData.display_order === 'asc' ? '昇順' : '降順' }}</p>
            <p><strong>表示数:</strong> {{ filterData.display_count || 'N/A' }} 件</p>
          </div>
          <div class="col-md-6">
            <p><strong>ブロックされた人を表示:</strong> {{ filterData.show_blocked_users ? 'はい' : 'いいえ' }}</p>
            <p><strong>非表示の人を表示:</strong> {{ filterData.show_hidden_users ? 'はい' : 'いいえ' }}</p>
            <p><strong>作成日:</strong> {{ formatDateTime(filterData.created_at) }}</p>
            <p><strong>更新日:</strong> {{ formatDateTime(filterData.updated_at) }}</p>
          </div>
        </div>
      </b-card>
    </div>
  </template>

<script>
export default {
  props: {
    filterData: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDateTime(time) {
      return time ? new Date(time).toLocaleString('ja-JP') : 'N/A';
    }
  }
};
</script>

  <style scoped>
  p {
    margin-bottom: 8px;
  }
  </style>